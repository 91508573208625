import { DataTable, DataTableContent } from '@rmwc/data-table';

import styled from '../styled-components';

export const FullWidthDataTable = styled(DataTable)`
  &&& {
    display: flex;
    border: 0;
  }
`;

export const FullWidthDataTableContent = styled(DataTableContent)`
  flex: 1;
  width: 100%;
`;
