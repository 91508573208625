import React, { useEffect, useState } from 'react';
import { RouteComponentProps, withRouter } from 'react-router';
import { Link } from 'react-router-dom';
import { useQueryParam, NumberParam, StringParam } from 'use-query-params';
import { ResponsivePie } from '@nivo/pie';

import { CircularProgress } from '@rmwc/circular-progress';
import { DataTableBody, DataTableHead, DataTableRow, DataTableCell } from '@rmwc/data-table';

import styled from '../../../styled-components';
import Pagination from '../../../components/Pagination';
import TableTitle from '../../../components/TableTitle';
import Defaults from '../../../constants/Defaults';
import StyledButton from '../../../components/StyledButton';
import { FullWidthDataTable, FullWidthDataTableContent } from '../../../components/Table';
import { useGetMediaByPage, useGetCountOta } from '../../../lib/api/Ota.hooks';
import { useGetFirmwareCount, FirmwareCount } from '../../../lib/api/Device.hooks';
import { StyledDataTableHeadCell } from '../../../components/StyledDataTableHeadCell';
import { Ota, DateRangeType } from '../../../types/Ota';
import parseSort from '../../../lib/parseSort';

const Container = styled.div`
  padding: 20px;
  background-color: #eee;
  flex: 1;
  display: flex;
  align-items: stretch;
  flex-direction: column;
`;

const TableContainer = styled.div`
  display: flex;
  align-items: stretch;
  flex-direction: column;
  border-radius: 10px;
  overflow: hidden;
  background-color: white;
`;

const FullWidthBar = styled.div`
  display: flex;
  flex-direction: row;
  margin: 32px 25px;
  justify-content: space-between;
  align-items: center;
`;

const PointerDataTableRow = styled(DataTableRow)`
  cursor: pointer;
`;

const StyledPagination = styled(Pagination)`
  align-self: flex-end;
`;

const SpinnerContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100px;
  width: 100%;
`;

const CustomCell = styled(DataTableCell)`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 200px;
`;

const TopContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 730px;
  background-color: white;
  border-radius: 10px;
  padding: 32px 25px;
  margin-bottom: 30px;
`;

const PieChartContainer = styled.div`
  height: 600px;
`;

const ToolTipText = styled.p`
  font-size: 14px;
  font-family: Roboto;
  font-weight: 500;
  margin: 0;
`;

function Spinner() {
  return (
    <SpinnerContainer>
      <CircularProgress size='large' />
    </SpinnerContainer>
  );
}

type OtaProps = {} & RouteComponentProps;

function OtaList(props: OtaProps) {
  const { history } = props;
  const [page, setPage] = useQueryParam('page', NumberParam);
  const [sort, setSort] = useQueryParam('sort', StringParam);
  const limitQuery = useQueryParam('limit', NumberParam);
  const [limit, setLimit] = useState(Number(limitQuery) || Defaults.LIST_PAGE_SIZE);
  const { data, isLoading, error, get } = useGetMediaByPage();
  const getCountOta = useGetCountOta();
  const getOtaCountGetter = getCountOta.get;
  const { get: getFirmwareCount, data: firmwareCount } = useGetFirmwareCount();
  const [chartData, setChartData] = useState([] as any);

  useEffect(() => {
    get({
      page: Number(page) || 0,
      sort: String(sort || ''),
      limit: Number(limit) || Defaults.LIST_PAGE_SIZE
    });
    getOtaCountGetter();
    getFirmwareCount();
  }, [get, page, sort, limit, getOtaCountGetter]);

  useEffect(() => {
    if (firmwareCount.length > 0) {
      let total = 0;
      firmwareCount.forEach((item: FirmwareCount) => {
        total += item.units;
      });
      setChartData(
        firmwareCount.map((item: FirmwareCount) => ({
          ...item,
          id: `Firmware ${item.firmware}`,
          value: parseFloat(((item.units * 100) / total).toFixed(2))
        }))
      );
    }
  }, [firmwareCount]);

  const setNewSort = (column: string, direction: number | null) => {
    const newSort = direction ? `${column} ${direction > 0 ? 'DESC' : 'ASC'}` : undefined;
    setSort(newSort);
  };

  const setNewPage = (page: number) => {
    page = page - 1;
    setPage(page);
  };

  const viewDetails = (otaId: number | string | undefined) => {
    history.push(`/cms/ota/${otaId}`);
  };

  const formatDateRange = (values?: any) => {
    const newValues = JSON.parse(values);
    if (newValues && newValues.length > 0) {
      const result = newValues.map((elem: DateRangeType) => {
        const { startYear, startWeek, startSequence, endYear, endWeek, endSequence } = elem;
        return `Y${startYear} W${startWeek} - ${startSequence} -- Y${endYear} W${endWeek} - ${endSequence}`;
      });
      return result.join('\r\n');
    }
    return '';
  };

  let sortObj;
  if (sort) {
    sortObj = parseSort(sort as string);
  }
  sortObj = sortObj || { column: '', direction: null };

  const hoverLabel = (elem: any) => {
    return (
      <div>
        <ToolTipText>{elem.id}</ToolTipText>
        <ToolTipText>
          {elem.value}% = {elem.units} units
        </ToolTipText>
      </div>
    );
  };

  const formatModels = (models: any) => (models === null ? 'All' : models.length === 0 ? 'None' : models.join(', '));

  return (
    <Container>
      <TopContainer>
        <TableTitle>CURRENT PEAK PRO POPULATION</TableTitle>
        <PieChartContainer>
          <ResponsivePie
            data={chartData}
            margin={{ top: 40, right: 80, bottom: 80, left: 80 }}
            startAngle={-90}
            innerRadius={0.5}
            cornerRadius={1}
            colors={{ scheme: 'paired' }}
            radialLabelsSkipAngle={10}
            radialLabelsLinkDiagonalLength={30}
            radialLabelsLinkColor={{ from: 'color', modifiers: [] }}
            slicesLabelsSkipAngle={15}
            sliceLabel={e => `${e.value}%`}
            tooltip={e => hoverLabel(e)}
            legends={[
              {
                anchor: 'bottom',
                direction: 'row',
                translateY: 56,
                itemWidth: 100,
                itemHeight: 18,
                itemTextColor: '#999',
                symbolSize: 18,
                symbolShape: 'circle',
                effects: [
                  {
                    on: 'hover',
                    style: {
                      itemTextColor: '#000'
                    }
                  }
                ]
              }
            ]}
          />
        </PieChartContainer>
      </TopContainer>
      <TableContainer>
        <FullWidthBar>
          <TableTitle>OTA</TableTitle>
          <StyledButton
            unelevated
            label='Add New OTA'
            style={{ width: 180, textTransform: 'inherit' }}
            {...{ tag: Link, to: '/cms/ota/new' }}
          />
        </FullWidthBar>
        <FullWidthDataTable>
          <FullWidthDataTableContent>
            <DataTableHead>
              <DataTableRow>
                <StyledDataTableHeadCell
                  sort={sortObj.column === 'id' ? sortObj.direction : null}
                  onSortChange={dir => {
                    setNewSort('id', dir);
                  }}
                >
                  ID
                </StyledDataTableHeadCell>
                <StyledDataTableHeadCell
                  sort={sortObj.column === 'version' ? sortObj.direction : null}
                  onSortChange={dir => {
                    setNewSort('version', dir);
                  }}
                >
                  Version
                </StyledDataTableHeadCell>
                <StyledDataTableHeadCell
                  sort={sortObj.column === 'rolloutPercentage' ? sortObj.direction : null}
                  onSortChange={dir => {
                    setNewSort('rolloutPercentage', dir);
                  }}
                >
                  Rollout %
                </StyledDataTableHeadCell>
                <StyledDataTableHeadCell
                  sort={sortObj.column === 'supersedes' ? sortObj.direction : null}
                  onSortChange={dir => {
                    setNewSort('supersedes', dir);
                  }}
                >
                  Date Range - Serialization
                </StyledDataTableHeadCell>
                <StyledDataTableHeadCell
                  sort={sortObj.column === 'currentQty' ? sortObj.direction : null}
                  onSortChange={dir => {
                    setNewSort('currentQty', dir);
                  }}
                >
                  Current Peak Pro Population
                </StyledDataTableHeadCell>
                <StyledDataTableHeadCell
                  sort={sortObj.column === 'releaseNotes' ? sortObj.direction : null}
                  onSortChange={dir => {
                    setNewSort('releaseNotes', dir);
                  }}
                >
                  Release Notes
                </StyledDataTableHeadCell>
                <StyledDataTableHeadCell>Allowed Models</StyledDataTableHeadCell>
              </DataTableRow>
            </DataTableHead>
            <DataTableBody>
              {!isLoading &&
                !error &&
                data &&
                data.map((row: Ota) => (
                  <PointerDataTableRow
                    key={row.id}
                    onClick={() => {
                      viewDetails(row.id);
                    }}
                  >
                    <CustomCell>{row.id}</CustomCell>
                    <CustomCell>{row.version}</CustomCell>
                    <CustomCell>{row.rolloutPercentage}</CustomCell>
                    <CustomCell style={{ whiteSpace: 'normal' }}>
                      {row.supersedes ? row.supersedes : formatDateRange(row.dateRange)}
                    </CustomCell>
                    <CustomCell>{row.currentQty}</CustomCell>
                    <CustomCell style={{ whiteSpace: 'normal' }}>{row.releaseNotes}</CustomCell>
                    <CustomCell>{formatModels(row.models)}</CustomCell>
                  </PointerDataTableRow>
                ))}
            </DataTableBody>
          </FullWidthDataTableContent>
        </FullWidthDataTable>
        {isLoading && <Spinner />}
        {getCountOta.data !== null && (
          <StyledPagination
            current={Number(page) + 1 || 1}
            total={Math.ceil(getCountOta.data / Number(limit))}
            currentLimit={Number(limit)}
            onLimitChange={(limit: number) => setLimit(limit)}
            onPageChange={(page: number) => setNewPage(page)}
          />
        )}
      </TableContainer>
    </Container>
  );
}
export default withRouter(OtaList);
