import styled from '../styled-components';

const TableTitle = styled.p`
  margin: 0;
  font-family: Roboto;
  font-weight: 500;
  text-transform: uppercase;
  font-size: 28px;
`;

export default TableTitle;
