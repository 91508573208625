import React, { useEffect, useState } from 'react';
import { RouteComponentProps, withRouter } from 'react-router';
import { Link } from 'react-router-dom';
import { useQueryParam, NumberParam, StringParam } from 'use-query-params';
import { useDebounce } from 'use-debounce';

import { Button } from '@rmwc/button';
import { CircularProgress } from '@rmwc/circular-progress';
import { DataTableBody, DataTableHead, DataTableRow, DataTableCell } from '@rmwc/data-table';

import styled from '../../../styled-components';
import Pagination from '../../../components/Pagination';
import Defaults from '../../../constants/Defaults';
import TableTitle from '../../../components/TableTitle';
import Checkbox from '../../../components/Checkbox';
import { FullWidthDataTable, FullWidthDataTableContent } from '../../../components/Table';
import { StyledDataTableHeadCell } from '../../../components/StyledDataTableHeadCell';
import { useExportUsers, useGetUsersByPage, useGetUsersCount } from '../../../lib/api/User.hooks';
import parseSort from '../../../lib/parseSort';
import theme from '../../../constants/Theme';

type UserListProps = RouteComponentProps;

const Container = styled.div`
  padding: 20px;
  background-color: #eee;
  flex: 1;
  display: flex;
  align-items: stretch;
  flex-direction: column;
`;

const TableContainer = styled.div`
  background-color: white;
  display: flex;
  align-items: stretch;
  flex-direction: column;
  border-radius: 10px;
  overflow: hidden;
`;

const FullWidthBar = styled.div`
  display: flex;
  flex-direction: row;
  margin: 32px 25px;
  justify-content: space-between;
  align-items: center;
`;

const PointerDataTableRow = styled(DataTableRow)`
  cursor: pointer;
`;

const StyledPagination = styled(Pagination)`
  align-self: flex-end;
`;

const SpinnerContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100px;
  width: 100%;
`;

const StyledTextField = styled.input`
  background-color: transparent;
  height: 44px;
  border: 1px solid ${theme.lightGrey};
  border-radius: 22px;
  padding: 0 15px;
  font-size: 14px;

  :focus {
    border: 2px solid ${theme.grey};
    outline: none;
  }
`;

const RightSideContainer = styled.div`
  display: flex;
  flex-direction: row;
`;

const StyledButton = styled(Button)`
  height: 44px !important;
  border-radius: 22px !important;
  margin-left: 15px;
`;

const CustomCell = styled(DataTableCell)`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 200px;
`;

const CustomCheckboxCell = styled(DataTableCell)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  row-gap: 5px;
  max-width: 200px;
`;

function Spinner() {
  return (
    <SpinnerContainer>
      <CircularProgress size='large' />
    </SpinnerContainer>
  );
}

// TODO handle error
function UserList(props: UserListProps) {
  const { history } = props;
  const [page, setPage] = useQueryParam('page', NumberParam);
  const [sort, setSort] = useQueryParam('sort', StringParam);
  const limitQuery = useQueryParam('limit', NumberParam);
  const [limit, setLimit] = useState(Number(limitQuery) || Defaults.LIST_PAGE_SIZE);
  const [searchString, setSearchString] = useQueryParam('search', StringParam);
  const [searchStringDebounce] = useDebounce(searchString, 500);

  const { data, isLoading, error, get } = useGetUsersByPage();
  const { countData, getCount, getUsersError, getUsersCountLoading } = useGetUsersCount();

  useEffect(() => {
    const filter: any = {
      page: Number(page) || 0,
      sort: String(sort || ''),
      limit: Number(limit) || Defaults.LIST_PAGE_SIZE
    };
    if (searchStringDebounce) {
      filter.search = searchStringDebounce;
    }
    get(filter);
    getCount(searchStringDebounce ? { search: searchStringDebounce } : {});
  }, [get, page, sort, limit, searchStringDebounce, getCount]);

  const setNewSort = (column: string, direction: number | null) => {
    const newSort = direction ? `${column} ${direction > 0 ? 'DESC' : 'ASC'}` : undefined;
    setSort(newSort);
  };

  const viewUser = (userId: number) => {
    history.push(`/cms/users/${userId}/view`);
  };

  const setNewPage = (page: number) => {
    page = page - 1;
    setPage(page);
  };

  let sortObj;
  if (sort) {
    sortObj = parseSort(sort as string);
  }
  sortObj = sortObj || { column: '', direction: null };

  const { get: exportUsers } = useExportUsers();

  return (
    <Container>
      <TableContainer>
        <FullWidthBar>
          <TableTitle>Users</TableTitle>
          <RightSideContainer>
            <StyledTextField
              type='text'
              placeholder='Search'
              onChange={(event: React.ChangeEvent<any>) => {
                setSearchString(event.target.value);
              }}
              value={searchString}
            />
            <StyledButton unelevated label='Add New User' icon='person_add' {...{ tag: Link, to: '/cms/users/new' }} />
            <StyledButton unelevated label='Export' onClick={() => exportUsers()} />
          </RightSideContainer>
        </FullWidthBar>
        <FullWidthDataTable>
          <FullWidthDataTableContent>
            <DataTableHead>
              <DataTableRow>
                <StyledDataTableHeadCell
                  sort={sortObj.column === 'id' ? sortObj.direction : null}
                  onSortChange={dir => {
                    setNewSort('id', dir);
                  }}
                >
                  ID
                </StyledDataTableHeadCell>
                <StyledDataTableHeadCell
                  sort={sortObj.column === 'username' ? sortObj.direction : null}
                  onSortChange={dir => {
                    setNewSort('username', dir);
                  }}
                >
                  Username
                </StyledDataTableHeadCell>
                <StyledDataTableHeadCell
                  sort={sortObj.column === 'email' ? sortObj.direction : null}
                  onSortChange={dir => {
                    setNewSort('email', dir);
                  }}
                >
                  Email
                </StyledDataTableHeadCell>
                <StyledDataTableHeadCell
                  sort={sortObj.column === 'verified' ? sortObj.direction : null}
                  onSortChange={dir => {
                    setNewSort('verified', dir);
                  }}
                >
                  Verified
                </StyledDataTableHeadCell>
                <StyledDataTableHeadCell
                  sort={sortObj.column === 'locked' ? sortObj.direction : null}
                  onSortChange={dir => {
                    setNewSort('locked', dir);
                  }}
                >
                  Locked
                </StyledDataTableHeadCell>
                <StyledDataTableHeadCell
                  sort={sortObj.column === 'market_opt_in' ? sortObj.direction : null}
                  onSortChange={dir => {
                    setNewSort('market_opt_in', dir);
                  }}
                >
                  Opt-In
                </StyledDataTableHeadCell>
              </DataTableRow>
            </DataTableHead>
            <DataTableBody>
              {!isLoading &&
                !error &&
                data &&
                data.map(row => (
                  <PointerDataTableRow key={row.id} onClick={() => viewUser(row.id)}>
                    <CustomCell>{row.id}</CustomCell>
                    <CustomCell>{row.username}</CustomCell>
                    <CustomCell>{row.email}</CustomCell>
                    <CustomCell>
                      <Checkbox checked={row.verified} />
                    </CustomCell>
                    <CustomCell>
                      <Checkbox checked={row.locked} />
                    </CustomCell>
                    <CustomCheckboxCell>
                      <Checkbox disabled text={'Email'} checked={row.marketOptIn === 'Email' || row.marketOptIn === 'All'} />
                      <Checkbox disabled text={'SMS'} checked={row.marketOptIn === 'Phone' || row.marketOptIn === 'All'} />
                    </CustomCheckboxCell>
                  </PointerDataTableRow>
                ))}
            </DataTableBody>
          </FullWidthDataTableContent>
        </FullWidthDataTable>
        {(isLoading || getUsersCountLoading) && <Spinner />}
        {countData !== null && countData !== 0 && !getUsersError && (
          <StyledPagination
            current={Number(page) + 1 || 1}
            total={Math.ceil(countData / Number(limit))}
            currentLimit={Number(limit)}
            onLimitChange={(limit: number) => setLimit(limit)}
            onPageChange={(page: number) => setNewPage(page)}
          />
        )}
      </TableContainer>
    </Container>
  );
}

export default withRouter(UserList);
