import React from 'react';

import { Link, withRouter, RouteComponentProps } from 'react-router-dom';

import { Drawer, DrawerContent, DrawerProps } from '@rmwc/drawer';
import { List, ListItem } from '@rmwc/list';

import styled from '../../styled-components';

type NavigationDrawerProps = RouteComponentProps &
  DrawerProps & {
    sections: any[];
  };

// https://www.styled-components.com/docs/faqs#how-can-i-override-styles-with-higher-specificity
const StyledDrawer = styled(Drawer)`
  &&& {
    width: 250px;
  }
`;

const StyledList = styled(List)`
  &&& {
    padding: 0;
  }
`;

const StyledListItem = styled(ListItem)`
  &&& {
    font-family: Roboto;
    font-size: 0.875rem;
    font-weight: normal;
    text-transform: uppercase;
    letter-spacing: 0.1em;
    margin: 0;
    padding: 28px 25px;
    border-radius: 0;
    border-bottom: 0.1px solid #f7f7f7;
    margin-bottom: -1px;
  }
`;

function NavigationDrawer(props: NavigationDrawerProps) {
  const { sections, location, ...others } = props;
  const { staticContext, ...rest } = others; // removed staticContext to fix warning

  return (
    <StyledDrawer {...rest}>
      <DrawerContent>
        <StyledList>
          {sections.map(section => (
            <StyledListItem
              ripple={false}
              selected={location.pathname.indexOf(section.path) === 0}
              key={section.path}
              // https://rmwc.io/library-integrations
              {...{ tag: Link, to: section.path }}
            >
              {section.name}
            </StyledListItem>
          ))}
        </StyledList>
      </DrawerContent>
    </StyledDrawer>
  );
}

export default withRouter(NavigationDrawer);
