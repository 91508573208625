import { useCallback } from 'react';

import Defaults from '../../constants/Defaults';
import { Message } from '../../types/Message';
import { HttpMethod } from './Api';
import { useApiGet, useApiMutation } from './Api.hooks';

export function useGetMessagesCount() {
  return useApiGet<number | null>(null, 'messages/count');
}

export function useGetMessagesByPage() {
  const { get: originalGet, ...rest } = useApiGet<Message[]>([], 'messages');

  const get = useCallback(
    async (params: { page?: number; query?: string; sort?: string; limit?: number }) => {
      const defaults = {
        page: 0,
        limit: Defaults.LIST_PAGE_SIZE
      };
      await originalGet({ ...defaults, ...params });
    },
    [originalGet]
  );
  return { ...rest, get };
}

export type MessageCreateDTO = {
  content: string;
};

export function useCreateMessage() {
  const { mutate, ...rest } = useApiMutation<Message, MessageCreateDTO>(null, HttpMethod.POST, 'messages');
  return { ...rest, create: mutate };
}
