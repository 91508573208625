import React, { useEffect } from 'react';
import { RouteComponentProps, withRouter } from 'react-router';
import { CircularProgress } from '@rmwc/circular-progress';

import { useGetUser } from '../../../lib/api/User.hooks';
import styled from '../../../styled-components';
import { useGetHeatProfiles } from '../../../lib/api/HeatProfile.hooks';
import { useGetUserDevices } from '../../../lib/api/Device.hooks';
import StyledButton from '../../../components/StyledButton';
import TableTitle from '../../../components/TableTitle';
import Checkbox from '../../../components/Checkbox';
import { FullWidthDataTable, FullWidthDataTableContent } from '../../../components/Table';
import { DataTableBody, DataTableHead, DataTableHeadCell, DataTableRow, DataTableCell } from '@rmwc/data-table';

type UserViewProps = RouteComponentProps<{ id: string }>;

const Container = styled.div`
  padding: 20px;
  background-color: #eee;
  flex: 1;
  display: flex;
  flex-direction: column;
`;

const UserTitleText = styled.p`
  color: #919191;
  font-size: 9pt;
  padding: 0 0 5px 0;
  font-size: 0.75rem;
  font-weight: 500;
  margin: 0;
`;

const RoleContainer = styled.div`
  display: flex;
  flex-direction: row;
`;

const StyledRole = styled.p`
  background-color: #e6e6e6;
  font-size: 0.9rem;
  padding: 3px 6px;
  margin: 0 5px 0 0;
`;

const StyledFullWidthDataTable = styled(FullWidthDataTable)`
  border-radius: 4px;
  background-color: #fff;
  background-color: #fff;
  background-color: var(--mdc-theme-surface, #fff);
  box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  margin-top: 20px;
`;

const StyledCard = styled.div`
  border-radius: 4px;
  background-color: #fff;
  background-color: #fff;
  background-color: var(--mdc-theme-surface, #fff);
  box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
`;

const Spinner = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 200px;
  width: 100%;
`;

const UserInfoContainer = styled.div`
  width: 100%;
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  padding: 20px;
  overflow: auto;
`;

const EmptyTableText = styled.p`
  text-align: center;
`;

const ButtonContainer = styled.div`
  display: fex;
  justify-content: space-between;
`;

const Buttons = styled.div`
  display: flex;
  justify-content: flex-start;

  & > * {
    margin: 5px;
  }
`;

const StyledUserData = styled.div`
  border-radius: 5px;
  border: 1px solid lightgrey;
  margin-bottom: 15px;
  display: flex;
  align-items: space-around;
  flex-direction: column;
  padding: 10px;
  height: auto;
  min-height: 60px;
`;

const PointerDataTableRow = styled(DataTableRow)`
  cursor: pointer;
`;

const StyledTableTitle = styled(TableTitle)`
  display: flex;
  flex-direction: row;
  margin: 10px 20px 5px;
  justify-content: space-between;
  align-items: center;
  font-size: 24px;
`;

const StyledCheckbox = styled(Checkbox)`
  height: 50px;
`;

function UserView(props: UserViewProps) {
  const { history, match } = props;
  const userId = match.params.id;
  const { data, isLoading, error, get } = useGetHeatProfiles(userId);
  const { data: userDevices, isLoading: isDevicesLoading, error: getDevicesError, get: getDevices } = useGetUserDevices(userId);
  const getUser = useGetUser(userId);
  const values = getUser.data;
  const ready = !getUser.isLoading;

  useEffect(() => {
    get();
    getDevices();
  }, [get, getDevices]);

  useEffect(() => {
    getUser.get();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getUser.get]);

  const handleCancel = () => {
    history.push('/cms/users/');
  };

  const editUser = () => {
    history.push(`/cms/users/${userId}`);
  };
  const viewDevice = (deviceId: number) => {
    history.push(`/cms/devices/${deviceId}/view/?prior=/cms/users/${userId}/view/`);
  };

  return (
    <Container>
      <StyledCard>
        <StyledTableTitle>Users</StyledTableTitle>
        {!ready && (
          <Spinner>
            <CircularProgress size='large' />
          </Spinner>
        )}
        {ready && values && (
          <UserInfoContainer>
            <StyledUserData>
              <UserTitleText>ID</UserTitleText> {values.id}
            </StyledUserData>
            <StyledUserData>
              <UserTitleText>Name</UserTitleText>
              {values.firstName ? values.firstName : ''} {values.lastName ? values.lastName : ''}
            </StyledUserData>
            <StyledUserData>
              <UserTitleText>Username</UserTitleText> {values.username}
            </StyledUserData>
            <StyledUserData>
              <UserTitleText>Email</UserTitleText> {values.email}
            </StyledUserData>
            <StyledCheckbox name='verified' label='Verified' checked={values.verified} />
            <StyledCheckbox name='locked' label='Locked' checked={values.locked} />
            <StyledCheckbox disabled name='email' label='Email' checked={values.marketOptIn === 'Email' || values.marketOptIn === 'All'} />
            <StyledCheckbox disabled name='sms' label='SMS' checked={values.marketOptIn === 'Phone' || values.marketOptIn === 'All'} />
            <StyledUserData>
              <UserTitleText> Roles</UserTitleText>
              <RoleContainer>{values.roles && values.roles.map(role => <StyledRole key={role.id}>{role.name}</StyledRole>)}</RoleContainer>
            </StyledUserData>
            <ButtonContainer>
              <Buttons>
                <StyledButton type='button' label='Back' onClick={handleCancel} />
                <StyledButton type='button' label='Edit User' onClick={editUser} />
              </Buttons>
            </ButtonContainer>
          </UserInfoContainer>
        )}
      </StyledCard>

      <StyledFullWidthDataTable>
        <StyledTableTitle>Devices</StyledTableTitle>
        <FullWidthDataTableContent>
          <DataTableHead>
            <DataTableRow>
              <DataTableHeadCell>ID</DataTableHeadCell>
              <DataTableHeadCell>Name</DataTableHeadCell>
              <DataTableHeadCell>Serial Number</DataTableHeadCell>
              <DataTableHeadCell>Model</DataTableHeadCell>
              <DataTableHeadCell>Firmware</DataTableHeadCell>
              <DataTableHeadCell>Date of Birth</DataTableHeadCell>
              <DataTableHeadCell>Githash</DataTableHeadCell>
              <DataTableHeadCell>Battery Capacity</DataTableHeadCell>
              <DataTableHeadCell>Hit Count</DataTableHeadCell>
              <DataTableHeadCell>Total Run Time</DataTableHeadCell>
            </DataTableRow>
          </DataTableHead>
          <DataTableBody>
            {!isDevicesLoading &&
              !getDevicesError &&
              userDevices &&
              userDevices.map(row => (
                <PointerDataTableRow key={row.id} onClick={() => viewDevice(row.id)}>
                  <DataTableCell>{row.id}</DataTableCell>
                  <DataTableCell>{row.name}</DataTableCell>
                  <DataTableCell>{row.serialNumber}</DataTableCell>
                  <DataTableCell>{row.model}</DataTableCell>
                  <DataTableCell>{row.firmware}</DataTableCell>
                  <DataTableCell>{row.dob}</DataTableCell>
                  <DataTableCell>{row.gitHash}</DataTableCell>
                  <DataTableCell>{row.batteryCapacity}</DataTableCell>
                  <DataTableCell>{row.hitCount}</DataTableCell>
                  <DataTableCell>{row.totalRuntime}</DataTableCell>
                </PointerDataTableRow>
              ))}
          </DataTableBody>
        </FullWidthDataTableContent>
        {data.length === 0 && <EmptyTableText>No devices</EmptyTableText>}
      </StyledFullWidthDataTable>
      {isDevicesLoading && (
        <Spinner>
          <CircularProgress size='large' />
        </Spinner>
      )}

      <StyledFullWidthDataTable>
        <StyledTableTitle>Heat Profiles</StyledTableTitle>
        <FullWidthDataTableContent>
          <DataTableHead>
            <DataTableRow>
              <DataTableHeadCell>ID</DataTableHeadCell>
              <DataTableHeadCell>Name</DataTableHeadCell>
              <DataTableHeadCell>Temperature</DataTableHeadCell>
              <DataTableHeadCell>Duration</DataTableHeadCell>
              <DataTableHeadCell>Color</DataTableHeadCell>
              <DataTableHeadCell>Lighting</DataTableHeadCell>
              <DataTableHeadCell>Order</DataTableHeadCell>
              <DataTableHeadCell>Units</DataTableHeadCell>
            </DataTableRow>
          </DataTableHead>

          <DataTableBody>
            {!isLoading &&
              !error &&
              data &&
              data.map(row => (
                <PointerDataTableRow key={row.id}>
                  <DataTableCell>{row.id}</DataTableCell>
                  <DataTableCell>{row.name}</DataTableCell>
                  <DataTableCell>{row.temperature}</DataTableCell>
                  <DataTableCell>{row.duration}</DataTableCell>
                  <DataTableCell>{row.color}</DataTableCell>
                  <DataTableCell>{row.lighting}</DataTableCell>
                  <DataTableCell>{row.order}</DataTableCell>
                  <DataTableCell>{row.units}</DataTableCell>
                </PointerDataTableRow>
              ))}
          </DataTableBody>
        </FullWidthDataTableContent>
        {data.length === 0 && <EmptyTableText>No heat profiles</EmptyTableText>}
      </StyledFullWidthDataTable>
      {isLoading && (
        <Spinner>
          <CircularProgress size='large' />
        </Spinner>
      )}
    </Container>
  );
}

export default withRouter(UserView);
