import React from 'react';
import { Route, Switch } from 'react-router';

import OtaList from './OtaList';
import OtaEdit from './OtaEdit';

function OtaModule() {
  return (
    <Switch>
      <Route exact path='/cms/ota/' component={OtaList} />
      <Route exact path='/cms/ota/:id' component={OtaEdit} />
      <Route exact path='/cms/ota/new' component={OtaEdit} />
    </Switch>
  );
}

export default OtaModule;
