import { useApiGet, useApiMutation } from './Api.hooks';
import { HttpMethod } from './Api';
import { useCallback } from 'react';
import { Ota } from '../../types/Ota';
import { AWSSignature } from '../../types/types';
import Defaults from '../../constants/Defaults';
import { useExportData } from '../hooks';

export function useCreateOta() {
  const { mutate, ...rest } = useApiMutation<any, any>(null, HttpMethod.POST, 'ota');

  const create = useCallback(
    async (ota: any) => {
      await mutate(ota);
    },
    [mutate]
  );

  return { ...rest, create };
}

export function useGetMediaByPage() {
  const { get: originalGet, ...rest } = useApiGet<Ota[]>([], 'ota/');

  const get = useCallback(
    async (params: { page?: number; query?: string; sort?: string; limit?: number }) => {
      const defaults = {
        page: 0,
        limit: Defaults.LIST_PAGE_SIZE
      };
      await originalGet({ ...defaults, ...params });
    },
    [originalGet]
  );

  return { ...rest, get };
}

export function useGetCountOta() {
  return useApiGet<number | null>(null, 'ota/count');
}

export function useGetSignature() {
  const { get: getSig, ...rest } = useApiGet<AWSSignature | null>(null, 'media/signature');
  return { ...rest, getSig };
}

export function useGetOta(otaId: string) {
  return useApiGet<Ota | null>(null, 'ota/' + otaId);
}

export function useGetOtaById() {
  const { get: getOta, ...rest } = useApiGet<Ota | null>(null, `ota/`);
  return { ...rest, getOta };
}

export function useUpdateOta(id: string) {
  const { mutate, ...rest } = useApiMutation<any, any>(null, HttpMethod.PATCH, 'ota/' + id);

  const update = useCallback(
    async (ota: any) => {
      await mutate(ota);
    },
    [mutate]
  );

  return { ...rest, update };
}

export function useExportOtaPreview() {
  const { get, data, response, ...rest } = useApiGet<any>(null, `ota/preview/export`, 'text');

  useExportData('devices', response, data);

  return { get, ...rest };
}

export function useExportOtaDownload(otaId: string) {
  const { get, data, response, ...rest } = useApiGet<any>(null, `ota/${otaId}/export`, 'text');

  useExportData('OTA_report_device_list', response, data);

  return { get, ...rest };
}
