import DeviceModule from './devices/DeviceModule';
import UserModule from './users/UserModule';
import MessageModule from './messages/MessageModule';
import OtaModule from './ota/OtaModule';
import InterstitialModule from './interstitials/InterstitialModule';

type RouteDefintion = {
  icon?: JSX.Element | null;
  name: string;
  path: string;
  component: () => JSX.Element;
};

export const CMSRouteSections: RouteDefintion[] = [
  {
    icon: null,
    name: 'Users',
    path: '/cms/users',
    component: UserModule
  },
  {
    icon: null,
    name: 'Messages',
    path: '/cms/messages',
    component: MessageModule
  },
  {
    name: 'Devices',
    path: '/cms/devices',
    component: DeviceModule
  },
  {
    name: 'OTA Firmware',
    path: '/cms/ota',
    component: OtaModule
  },
  {
    name: 'Interstitials',
    path: '/cms/interstitial',
    component: InterstitialModule
  }
];

export default CMSRouteSections;
