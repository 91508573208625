import React, { useEffect, useState } from 'react';
import { RouteComponentProps, withRouter } from 'react-router';
import { Link } from 'react-router-dom';
import { useQueryParam, NumberParam, StringParam } from 'use-query-params';

import { CircularProgress } from '@rmwc/circular-progress';
import { DataTableBody, DataTableHead, DataTableRow, DataTableCell } from '@rmwc/data-table';

import styled from '../../../styled-components';
import Defaults from '../../../constants/Defaults';
import TableTitle from '../../../components/TableTitle';
import Pagination from '../../../components/Pagination';
import StyledButton from '../../../components/StyledButton';
import { FullWidthDataTable, FullWidthDataTableContent } from '../../../components/Table';
import { useGetMessagesByPage, useGetMessagesCount } from '../../../lib/api/Message.hooks';
import parseSort from '../../../lib/parseSort';
import { StyledDataTableHeadCell } from '../../../components/StyledDataTableHeadCell';

type MessageListProps = RouteComponentProps;

const Container = styled.div`
  padding: 20px;
  background-color: #eee;
  flex: 1;
  display: flex;
  align-items: stretch;
  flex-direction: column;
`;

const TableContainer = styled.div`
  background-color: white;
  display: flex;
  align-items: stretch;
  flex-direction: column;
  border-radius: 10px;
  overflow: hidden;
`;

const FullWidthBar = styled.div`
  display: flex;
  flex-direction: row;
  margin: 32px 25px;
  justify-content: space-between;
  align-items: center;
`;

const StyledPagination = styled(Pagination)`
  align-self: flex-end;
`;

const SpinnerContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100px;
  width: 100%;
`;

const CustomCell = styled(DataTableCell)`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 200px;
`;

function Spinner() {
  return (
    <SpinnerContainer>
      <CircularProgress size='large' />
    </SpinnerContainer>
  );
}

function MessageList(props: MessageListProps) {
  const [page, setPage] = useQueryParam('page', NumberParam);
  const [sort, setSort] = useQueryParam('sort', StringParam);
  const limitQuery = useQueryParam('limit', NumberParam);
  const [limit, setLimit] = useState(Number(limitQuery) || Defaults.LIST_PAGE_SIZE);

  const { data, isLoading, error, get } = useGetMessagesByPage();
  const getMessageCount = useGetMessagesCount();
  const getMessageCountGetter = getMessageCount.get;

  useEffect(() => {
    get({
      page: Number(page) || 0,
      sort: String(sort || ''),
      limit: Number(limit) || Defaults.LIST_PAGE_SIZE
    });
    getMessageCountGetter();
  }, [get, page, sort, limit, getMessageCountGetter]);

  const setNewSort = (column: string, direction: number | null) => {
    const newSort = direction ? `${column} ${direction > 0 ? 'DESC' : 'ASC'}` : undefined;
    setSort(newSort);
  };

  const setNewPage = (page: number) => {
    page = page - 1;
    setPage(page);
  };
  const formatDate = (current_datetime: Date | string) => {
    const date = new Date(current_datetime);
    return date.getMonth() + 1 + '-' + date.getDate() + '-' + date.getFullYear();
  };

  let sortObj;
  if (sort) {
    sortObj = parseSort(sort as string);
  }
  sortObj = sortObj || { column: '', direction: null };

  return (
    <Container>
      <TableContainer>
        <FullWidthBar>
          <TableTitle>Push Notifications</TableTitle>
          <StyledButton unelevated label='Create Push Notification' icon='send' {...{ tag: Link, to: '/cms/messages/new' }} />
        </FullWidthBar>
        <FullWidthDataTable>
          <FullWidthDataTableContent>
            <DataTableHead>
              <DataTableRow>
                <StyledDataTableHeadCell
                  sort={sortObj.column === 'id' ? sortObj.direction : null}
                  onSortChange={dir => {
                    setNewSort('id', dir);
                  }}
                >
                  ID
                </StyledDataTableHeadCell>
                <StyledDataTableHeadCell
                  sort={sortObj.column === 'content' ? sortObj.direction : null}
                  onSortChange={dir => {
                    setNewSort('content', dir);
                  }}
                >
                  Content
                </StyledDataTableHeadCell>
                <StyledDataTableHeadCell
                  sort={sortObj.column === 'created' ? sortObj.direction : null}
                  onSortChange={dir => {
                    setNewSort('created', dir);
                  }}
                >
                  Created
                </StyledDataTableHeadCell>
              </DataTableRow>
            </DataTableHead>
            <DataTableBody>
              {!isLoading &&
                !error &&
                data &&
                data.map(row => (
                  <DataTableRow key={row.id}>
                    <CustomCell style={{ marginLeft: '15px' }}>{row.id}</CustomCell>
                    <CustomCell style={{ whiteSpace: 'normal' }}>{row.content}</CustomCell>
                    <CustomCell style={{ width: '125px' }}>{formatDate(row.created)}</CustomCell>
                  </DataTableRow>
                ))}
            </DataTableBody>
          </FullWidthDataTableContent>
        </FullWidthDataTable>
        {isLoading && <Spinner />}
        {getMessageCount.data !== null && (
          <StyledPagination
            current={Number(page) + 1 || 1}
            total={Math.ceil(getMessageCount.data / Number(limit))}
            currentLimit={Number(limit)}
            onLimitChange={(limit: number) => setLimit(limit)}
            onPageChange={(page: number) => setNewPage(page)}
          />
        )}
      </TableContainer>
    </Container>
  );
}

export default withRouter(MessageList);
