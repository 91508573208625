export enum ErrorMessages {
  INVALID_CREDENTIALS = 'The email and password do not match. Please check again.',
  NETWORK_ERROR = 'There is a network issue. Please try again later.',
  LOGOUT_ERROR = 'An error occured when trying to logout. Please try again.',
  ACCOUNT_UPDATE_ERROR = 'There was an error updating your account.',
  PERMISSION_REQUIRED = 'Permission required',
  PERMISSIONS_REQUIRED = 'Permissions required',
  INVALID_SESSION = 'Please login again.',

  // Fields
  INVALID_EMAIL = 'Invalid email',
  REQUIRED_EMAIL = 'Email is required',
  REQUIRED_PASSWORD = 'Password is required',
  REQUIRED_MESSAGE = 'Message is required',
  INVALID_DATE_RANGE = 'Dates overlap, please re-enter.',
  REQUIRED_DESCRIPTION = 'Description is required',
  REQUIRED_URL = 'URL is required',
  REQUIRED_USERNAME = 'Username is required',
  REQUIRED_VERSION = 'Version is required',
  REQUIRED_RELEASENOTES = 'Release Notes is required',
  REQUIRED_PERCENTAGE = 'Rollout Percentage is required',
  REQUIRED_FIRSTNAME = 'First name is required',
  REQUIRED_LASTNAME = 'Last name is required',
  MAX_CHARACTERS = 'Character limit exceeded'
}

export enum AlertTitles {
  ACCOUNT_UPDATED = 'Account Updated',
  UNKOWN_ERROR = 'An error occured',
  INVALID_LOGIN = 'Invalid Login',
  CONFIRM_LOGOUT = 'Log Out',
  SUCCESS = 'Success',
  RESET_DETAILS_FAILED = 'Reset details failed',
  RESET_PASSWORD_FAILED = 'Reset password failed.',
  ACCOUNT_UPDATE_ERROR = 'Account Update Error',
  PERMISSIONS_ERROR = 'Permissions error'
}

export enum AlertMessages {
  LOGOUT = 'Are you sure you want to log out?'
}

export enum AlertButtons {
  LOGOUT_CONFIRM = 'Log Out'
}

export enum SuccessMessages {
  ACCOUNT_UPDATED_SUCCEEDED = 'Your account has been updated.'
}

export enum ResetPasswordStrings {
  RESET_PASSWORD_TITLE = 'Set your New Password',
  RESET_PASSWORD_DESCRIPTION = 'Enter details below to set a new password',
  RESET_PASSWORD_NEW_PASSWORD_LABEL = 'New Password',
  RESET_PASSWORD_CONFIRM_PASSWORD_LABEL = 'Confirm Password',
  RESET_PASSWORD_NEW_PASSWORD_ERROR_MESSAGE = 'New Password is required',
  RESET_PASSWORD_CONFIRM_PASSWORD_ERROR_MESSAGE = 'Confirm Password is required',
  RESET_PASSWORD_DOES_NOT_MATCH_ERROR_MESSAGE = "Password doesn't match"
}

export enum ButtonStrings {
  BUTTON_CONFIRM = 'Confirm'
}
