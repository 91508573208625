import { useCallback } from 'react';

import Defaults from '../../constants/Defaults';
import { Interstitial } from '../../types/Interstitial';
import { HttpMethod } from './Api';
import { useApiGet, useApiMutation } from './Api.hooks';

export function useGetInterstitialsCount() {
  return useApiGet<number | null>(null, 'interstitials/count');
}

export function useGetInterstitialsByPage() {
  const { get: originalGet, data, ...rest } = useApiGet<Interstitial[]>([], 'interstitials');

  const get = useCallback(
    async (params: { page?: number; query?: string; sort?: string; limit?: number }) => {
      const defaults = {
        page: 0,
        limit: Defaults.LIST_PAGE_SIZE
      };
      await originalGet({ ...defaults, ...params });
    },
    [originalGet]
  );
  return { ...rest, data: data?.map((d) => ({ ...d, startDate: new Date(d.startDate), endDate: new Date(d.endDate) })), get };
}

export function useGetActiveInterstitial() {
  return useApiGet<Interstitial | null>(null, 'interstitials/active');
}

export function useGetInterstitial(id: number) {
  return useApiGet<Interstitial | null>(null, 'interstitials/' + id);
}

export type InterstitialCreateDTO = {
  description: string;
  url: string;
  startDate: Date;
  endDate: Date;
};

export type InterstitialUpdateDTO = {
  id: number;
  description?: string;
  url: string;
  startDate: Date;
  endDate: Date;
};

export function useCreateInterstitial() {
  const { mutate, ...rest } = useApiMutation<Interstitial, InterstitialCreateDTO>(null, HttpMethod.POST, 'interstitials');
  return { ...rest, create: mutate };
}

export function useDeleteInterstitial(interstitialId: string) {
  const { mutate, ...rest } = useApiMutation<Interstitial, {}>(null, HttpMethod.DELETE, 'interstitials/' + interstitialId);

  const interstitialDelete = useCallback(async () => {
    await mutate();
  }, [mutate]);

  return { ...rest, interstitialDelete };
}

export function useEditInterstitial(interstitialId: number) {
  const { mutate, ...rest } = useApiMutation<Interstitial, InterstitialUpdateDTO>(null, HttpMethod.PUT, `interstitials/${interstitialId}`);
  return { ...rest, edit: mutate };
}
