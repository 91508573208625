import React from 'react';
import { Route, Switch } from 'react-router';

import UserEdit from './UserEdit';
import UserList from './UserList';
import UserView from './UserView';

function UserModule() {
  return (
    <Switch>
      <Route exact path='/cms/users/' component={UserList} />
      <Route exact path='/cms/users/:id' component={UserEdit} />
      <Route exact path='/cms/users/new' component={UserEdit} />
      <Route exact path='/cms/users/:id/view' component={UserView} />
    </Switch>
  );
}

export default UserModule;
