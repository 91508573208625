import React, { useEffect, useState } from 'react';
import { RouteComponentProps, withRouter } from 'react-router';
import { useQueryParam, NumberParam, StringParam } from 'use-query-params';
import { useDebounce } from 'use-debounce';

import { Button } from '@rmwc/button';
import { CircularProgress } from '@rmwc/circular-progress';
import { DataTableBody, DataTableHead, DataTableRow, DataTableCell } from '@rmwc/data-table';

import styled from '../../../styled-components';
import TableTitle from '../../../components/TableTitle';
import Pagination from '../../../components/Pagination';
import { StyledDataTableHeadCell } from '../../../components/StyledDataTableHeadCell';
import Defaults from '../../../constants/Defaults';
import { FullWidthDataTable, FullWidthDataTableContent } from '../../../components/Table';
import { useExportPeakDevices, useGetDevicesByPage, useGetDevicesCount } from '../../../lib/api/Device.hooks';
import parseSort from '../../../lib/parseSort';
import theme from '../../../constants/Theme';

type DeviceListProps = RouteComponentProps;

const Container = styled.div`
  padding: 20px;
  background-color: #eee;
  flex: 1;
  display: flex;
  align-items: stretch;
  flex-direction: column;
`;

const RightSideContainer = styled.div`
  display: flex;
  flex-direction: row;
`;

const TableContainer = styled.div`
  background-color: white;
  display: flex;
  align-items: stretch;
  flex-direction: column;
  border-radius: 10px;
  overflow: hidden;
`;

const FullWidthBar = styled.div`
  display: flex;
  flex-direction: row;
  margin: 32px 25px;
  justify-content: space-between;
  align-items: center;
`;

const PointerDataTableRow = styled(DataTableRow)`
  cursor: pointer;
`;

const StyledPagination = styled(Pagination)`
  align-self: flex-end;
`;

const SpinnerContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100px;
  width: 100%;
`;

const StyledButton = styled(Button)`
  height: 44px !important;
  border-radius: 22px !important;
  margin-left: 15px;
`;

const EmptyTableText = styled.p`
  text-align: center;
`;

const CustomCell = styled(DataTableCell)`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 200px;
`;

const StyledTextField = styled.input`
  background-color: transparent;
  height: 44px;
  border: 1px solid ${theme.lightGrey};
  border-radius: 22px;
  padding: 0 15px;
  font-size: 14px;

  :focus {
    border: 2px solid ${theme.grey};
    outline: none;
  }
`;

const Spinner = () => {
  return (
    <SpinnerContainer>
      <CircularProgress size='large' />
    </SpinnerContainer>
  );
};

const DeviceList = (props: DeviceListProps) => {
  const { history, location } = props;
  const [page, setPage] = useQueryParam('page', NumberParam);
  const [sort, setSort] = useQueryParam('sort', StringParam);
  const limitQuery = useQueryParam('limit', NumberParam);
  const [limit, setLimit] = useState(Number(limitQuery) || Defaults.LIST_PAGE_SIZE);
  const [searchString, setSearchString] = useQueryParam('search', StringParam);
  const [searchStringDebounce] = useDebounce(searchString, 500);

  const { data, isLoading, error, get } = useGetDevicesByPage();
  const { countData, getCount, getDeviceError, getDeviceCountLoading } = useGetDevicesCount();

  useEffect(() => {
    const filter: any = {
      page: Number(page) || 0,
      sort: String(sort || ''),
      limit: Number(limit) || Defaults.LIST_PAGE_SIZE
    };
    if (searchStringDebounce) {
      filter.search = searchStringDebounce;
    }
    get(filter);
    getCount(searchStringDebounce ? { search: searchStringDebounce } : {});
  }, [get, page, sort, limit, searchStringDebounce, getCount]);

  const setNewSort = (column: string, direction: number | null) => {
    const newSort = direction ? `${column} ${direction > 0 ? 'DESC' : 'ASC'}` : undefined;
    setSort(newSort);
  };

  const setNewPage = (page: number) => {
    page = page - 1;
    setPage(page);
  };

  const formatDate = (current_datetime: string) => {
    const date = new Date(current_datetime);
    return date.getMonth() + 1 + '-' + date.getDate() + '-' + date.getFullYear();
  };

  const viewDevice = (deviceId: number) => {
    const locationString = encodeURIComponent(`${location.pathname}${location.search}${location.hash}`);
    history.push(`/cms/devices/${deviceId}/view?prior=${locationString}`);
  };

  let sortObj;
  if (sort) {
    sortObj = parseSort(sort as string);
  }
  sortObj = sortObj || { column: '', direction: null };

  const { get: exportPeakDevices } = useExportPeakDevices();

  return (
    <Container>
      <TableContainer>
        <FullWidthBar>
          <TableTitle>Devices</TableTitle>
          <RightSideContainer>
            <StyledTextField
              type='text'
              placeholder='Search'
              onChange={(event: React.ChangeEvent<any>) => {
                setSearchString(event.target.value);
              }}
              value={searchString}
            />
            <StyledButton unelevated label='Export' onClick={() => exportPeakDevices()} />
          </RightSideContainer>
        </FullWidthBar>
        <FullWidthDataTable>
          <FullWidthDataTableContent>
            <DataTableHead>
              <DataTableRow>
                <StyledDataTableHeadCell
                  sort={sortObj.column === 'id' ? sortObj.direction : null}
                  onSortChange={dir => {
                    setNewSort('id', dir);
                  }}
                >
                  ID
                </StyledDataTableHeadCell>
                <StyledDataTableHeadCell
                  sort={sortObj.column === 'name' ? sortObj.direction : null}
                  onSortChange={dir => {
                    setNewSort('name', dir);
                  }}
                >
                  Name
                </StyledDataTableHeadCell>
                <StyledDataTableHeadCell
                  sort={sortObj.column === 'serialNumber' ? sortObj.direction : null}
                  onSortChange={dir => {
                    setNewSort('serialNumber', dir);
                  }}
                >
                  Serial Number
                </StyledDataTableHeadCell>
                <StyledDataTableHeadCell
                  sort={sortObj.column === 'model' ? sortObj.direction : null}
                  onSortChange={dir => {
                    setNewSort('model', dir);
                  }}
                >
                  Model
                </StyledDataTableHeadCell>
                <StyledDataTableHeadCell
                  sort={sortObj.column === 'hitCount' ? sortObj.direction : null}
                  onSortChange={dir => {
                    setNewSort('hitCount', dir);
                  }}
                >
                  Hit Count
                </StyledDataTableHeadCell>
                <StyledDataTableHeadCell
                  sort={sortObj.column === 'totalRuntime' ? sortObj.direction : null}
                  onSortChange={dir => {
                    setNewSort('totalRuntime', dir);
                  }}
                >
                  Total Runtime
                </StyledDataTableHeadCell>
                <StyledDataTableHeadCell
                  sort={sortObj.column === 'dob' ? sortObj.direction : null}
                  onSortChange={dir => {
                    setNewSort('dob', dir);
                  }}
                >
                  Date of Birth
                </StyledDataTableHeadCell>
                <StyledDataTableHeadCell
                  sort={sortObj.column === 'firmware' ? sortObj.direction : null}
                  onSortChange={dir => {
                    setNewSort('firmware', dir);
                  }}
                >
                  Firmware
                </StyledDataTableHeadCell>
                <StyledDataTableHeadCell
                  sort={sortObj.column === 'user.id' ? sortObj.direction : null}
                  onSortChange={dir => {
                    setNewSort('user.id', dir);
                  }}
                >
                  User Id
                </StyledDataTableHeadCell>
              </DataTableRow>
            </DataTableHead>
            <DataTableBody>
              {!isLoading &&
                !error &&
                data &&
                data.map(row => (
                  <PointerDataTableRow key={row.id} onClick={() => viewDevice(row.id)}>
                    <CustomCell>{row.id}</CustomCell>
                    <CustomCell>{row.name}</CustomCell>
                    <CustomCell>{row.serialNumber}</CustomCell>
                    <CustomCell>{row.model}</CustomCell>
                    <CustomCell>{row.hitCount}</CustomCell>
                    <CustomCell>{row.totalRuntime}</CustomCell>
                    <CustomCell>{formatDate(row.dob)}</CustomCell>
                    <CustomCell>{row.firmware}</CustomCell>
                    <CustomCell>{row.user && row.user.id}</CustomCell>
                  </PointerDataTableRow>
                ))}
            </DataTableBody>
          </FullWidthDataTableContent>
        </FullWidthDataTable>
        {(getDeviceCountLoading || isLoading) && <Spinner />}
        {countData === 0 && <EmptyTableText>No Devices</EmptyTableText>}
        {countData !== 0 && !getDeviceError && (
          <StyledPagination
            current={Number(page) + 1 || 1}
            total={Math.ceil((countData || 0) / Number(limit))}
            currentLimit={Number(limit)}
            onLimitChange={(limit: number) => setLimit(limit)}
            onPageChange={(page: number) => setNewPage(page)}
          />
        )}
      </TableContainer>
    </Container>
  );
};

export default withRouter(DeviceList);
