import React, { useEffect, useState } from 'react';
import { RouteComponentProps, withRouter } from 'react-router';

import { Card } from '@rmwc/card';
import { CircularProgress } from '@rmwc/circular-progress';

import { Form, Formik, FormikActions } from 'formik';
import * as Yup from 'yup';

import { ErrorMessages } from '../../../constants/Strings';
import TextField from '../../../components/TextField';
import TableTitle from '../../../components/TableTitle';
import StyledButton from '../../../components/StyledButton';
import { useCreateMessage } from '../../../lib/api/Message.hooks';
import CmsSnackbarQueue from '../../../lib/CmsSnackbarQueue';
import styled from '../../../styled-components';

type MessageCreateProps = RouteComponentProps;
type MessageFormType = {
  content: string;
};

const MessageCreateSchema = Yup.object().shape({
  content: Yup.string()
    .required(ErrorMessages.REQUIRED_MESSAGE)
    .max(250, ErrorMessages.MAX_CHARACTERS)
});

const Container = styled.div`
  display: flex;
  flex: 1;
  padding: 20px;
  background-color: #eee;
  flex-direction: column;
`;

const StyledCard = styled(Card)`
  display: flex;
`;

const FormContainer = styled(Form)`
  display: flex;
  flex-direction: column;
  width: 100%;
  flex: 1;
  justify-content: flex-start;
  padding: 5px 20px 20px;
`;

const StyledTextField = styled(TextField)`
  margin-top: 10px;
  margin-bottom: 10px;
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: space-between;
`;

const CharactersRemaining = styled.text`
  color: #666666;
  font-size: 10pt;
  height: 20px;
  vertical-align: middle;
  margin-top: 5px;
`;

const Buttons = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center & > * {
    margin: 5px;
  }
`;

const StyledTableTitle = styled(TableTitle)`
  display: flex;
  flex-direction: row;
  margin: 10px 20px 5px;
  justify-content: space-between;
  align-items: center;
`;

function MessageCreate(props: MessageCreateProps) {
  const { history } = props;

  const createMessage = useCreateMessage();
  const [charsRemaining, setCharsRemaining] = useState(250);

  useEffect(() => {
    if (createMessage.data && !createMessage.error && !createMessage.isLoading) {
      CmsSnackbarQueue.notify({
        title: `Push notification sent successfully.`,
        actions: [
          {
            label: 'DONE',
            onClick: () => history.push('/cms/messages/')
          }
        ]
      });
      history.push('/cms/messages');
    }
  }, [history, createMessage.data, createMessage.error, createMessage.isLoading]);

  useEffect(() => {
    if (createMessage.data && createMessage.error && !createMessage.isLoading) {
      CmsSnackbarQueue.notify({
        title: `Push notification failed. Please try again later.`,
        actions: [
          {
            label: 'DONE',
            onClick: () => history.push('/cms/messages/')
          }
        ]
      });
    }
  }, [history, createMessage.data, createMessage.error, createMessage.isLoading]);

  const handleSubmit = async (values: MessageFormType, actions: FormikActions<MessageFormType>) => {
    actions.setSubmitting(true);

    const newMessage = {
      content: values.content
    };
    await createMessage.create(newMessage);

    actions.setSubmitting(false);
  };

  const handleCancel = () => {
    history.goBack();
  };

  const initialValues = { content: '' };

  return (
    <Container>
      <StyledCard>
        <StyledTableTitle>Push Notification Message</StyledTableTitle>
        <Formik initialValues={initialValues} validationSchema={MessageCreateSchema} validateOnBlur={true} onSubmit={handleSubmit}>
          {({ values, errors, touched, handleChange, handleBlur, isSubmitting }) => (
            <FormContainer>
              {charsRemaining < 20 ? (
                <CharactersRemaining>{charsRemaining} Characters remaining</CharactersRemaining>
              ) : (
                <CharactersRemaining />
              )}
              <StyledTextField
                outlined
                type='text'
                name='content'
                label='message'
                autoComplete='off'
                characterCount
                maxLength={250}
                onChange={(e: any) => {
                  handleChange(e);
                  setCharsRemaining(250 - e.target.value.length);
                }}
                onBlur={handleBlur}
                value={values.content}
                helpText={{ persistent: true, validationMsg: true, children: touched.content && errors.content }}
              />
              <ButtonContainer>
                <Buttons>
                  <StyledButton
                    unelevated
                    type='submit'
                    label='Send'
                    disabled={isSubmitting}
                    icon={isSubmitting ? <CircularProgress /> : null}
                  />
                  <StyledButton type='button' label='Cancel' onClick={handleCancel} style={{ marginLeft: 10 }} />
                </Buttons>
              </ButtonContainer>
            </FormContainer>
          )}
        </Formik>
      </StyledCard>
    </Container>
  );
}

export default withRouter(MessageCreate);
