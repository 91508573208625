/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import { Button } from '@rmwc/button';
import { Typography } from '@rmwc/typography';

import styled from '../styled-components';
import theme from '../constants/Theme';
import Defaults from '../constants/Defaults';

const Container = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  width: 100%;
  padding-right: 30px;
  background-color: black;
`;

const RightContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const PageLimitContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-right: 70px;
`;

const PageContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: 0 5px;
`;

const StyledButton = styled(Button)`
  &&& {
    min-width: 30px;
    height: 30px;
    padding: 0;
    padding-left: 8px;
    margin: 0 5px;
    border-radius: 50%;
    align-items: center;
  }
`;

const TextButton = styled(Button)`
  &&& {
    min-width: 62px;
    height: 25px;
    margin: 0 2px;
    border-radius: 16px;
    font-size: 12px;
    text-transform: capitalize;
  }
`;

const StyledSelect = styled.select`
  width: 50px;
  height: 32px;
  margin: 0px 10px;

  :focus {
    outline-color: ${theme.grey};
  }
`;

const StyledTextField = styled.input`
  width: 65px;
  height: 32px;
  padding: 10px;
  margin: 10px 5px;
  border: none;
  text-align: center;

  :focus {
    outline-color: ${theme.grey};
  }
`;

type PaginationProps = {
  className?: string;
  current: number;
  total: number;
  currentLimit: number;
  onLimitChange: (limit: number) => void;
  onPageChange: (page: number) => void;
};

function Pagination(props: PaginationProps) {
  const { className, current, total, currentLimit, onPageChange, onLimitChange } = props;
  const [page, setPage] = useState(current);
  const [limit, setLimit] = useState(currentLimit);
  const pageLimit = Defaults.PAGE_LIMIT_OPTIONS;

  useEffect(() => {
    if (page > total) {
      goToPage(total);
    }
  }, [total]);

  useEffect(() => {
    goToPage(page);
  }, [page]);

  useEffect(() => {
    onLimitChange(limit);
  }, [limit]);

  const textButtonStyle = (value: number) => {
    if (current === value) {
      return { color: theme.grey, borderColor: theme.grey };
    }
    return { color: theme.white, borderColor: theme.white };
  };

  const iconButtonStyle = (value: number) => {
    if (current === value) {
      return { color: theme.lightGrey, backgroundColor: theme.grey };
    }
    return { color: theme.white, backgroundColor: theme.lightGrey };
  };

  const goToPage = (value: any) => {
    if (value <= total && value >= 1) {
      onPageChange(value);
      setPage(value);
    }
  };

  return (
    <Container className={className}>
      <PageLimitContainer>
        <Typography use='body1' style={{ color: theme.white }}>
          Show
        </Typography>
        <StyledSelect
          value={limit}
          onChange={(event: React.ChangeEvent<any>) => {
            setLimit(event.target.value);
          }}
        >
          {pageLimit.map((x, i) => (
            <option key={i} value={x}>
              {x}
            </option>
          ))}
        </StyledSelect>
        <Typography use='body1' style={{ color: theme.white }}>
          Per page
        </Typography>
      </PageLimitContainer>
      <RightContainer>
        <TextButton outlined label='First' onClick={() => goToPage(1)} style={textButtonStyle(1)} />
        <StyledButton icon={'navigate_before'} onClick={() => goToPage(current - 1)} style={iconButtonStyle(1)} />
        <PageContainer>
          <StyledTextField
            value={page}
            onChange={(event: React.ChangeEvent<any>) => {
              setPage(event.target.value);
            }}
          />
          <Typography use='body1' style={{ color: theme.white }}>
            / {total}
          </Typography>
        </PageContainer>
        <StyledButton icon={'navigate_next'} onClick={() => goToPage(current + 1)} style={iconButtonStyle(total)} />
        <TextButton outlined label='Last' onClick={() => goToPage(total)} style={textButtonStyle(total)} />
      </RightContainer>
    </Container>
  );
}

export default Pagination;
