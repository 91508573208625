import React from 'react';
import { Checkbox as RMWCCheckbox } from '@rmwc/checkbox';
import styled from 'styled-components';

const StyledCheckbox = styled(RMWCCheckbox)`
  .mdc-checkbox__native-control: enabled: checked ~.mdc-checkbox__background,
    .mdc-checkbox__native-control: enabled: indeterminate ~.mdc-checkbox__background {
    background-color: black;
    border-color: black;
  }
`;

const Container = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

function Checkbox(props: any) {
  const { className, text, ...rest } = props;
  return (
    <Container className={className}>
      <>{text}</>
      <StyledCheckbox {...rest} />
    </Container>
  );
}

export default Checkbox;
