import React from 'react';
import { Route, Switch } from 'react-router';

import DeviceList from './DeviceList';
import DeviceView from './DeviceView';

function DeviceModule() {
  return (
    <Switch>
      <Route exact path='/cms/devices/' component={DeviceList} />
      <Route exact path='/cms/devices/:id/view/' component={DeviceView} />
    </Switch>
  );
}

export default DeviceModule;
