import React from 'react';
import * as styledComponents from 'styled-components';
import { ThemedStyledComponentsModule } from 'styled-components';

import { Theme } from './constants/Theme';

const s: ThemedStyledComponentsModule<Theme> = styledComponents as any;

const styled = s.default;

export const css = s.css;
export const withTheme = s.withTheme;
export const ThemeProvider = s.ThemeProvider;
export const createGlobalStyle = s.createGlobalStyle;

export function withDefaults<T extends React.ComponentType<any>, U extends Partial<React.ComponentProps<T> & JSX.IntrinsicAttributes>>(
  Component: T,
  defaults: U
): React.FC<Omit<React.ComponentProps<T>, keyof U> & Partial<U>> {
  return props => React.createElement(Component, { ...defaults, ...props });
}

export default styled;
