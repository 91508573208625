import Cookies from 'js-cookie';
import Defaults from '../../constants/Defaults';
import { Deferred } from '../Deferred';
import { ACCESS_TOKEN_HEADER_KEY, HttpMethod, StaticUrls } from './Api';

const queue: Deferred<boolean>[] = [];

const getDefaultHeaders = (accessToken: string) => ({
  [ACCESS_TOKEN_HEADER_KEY]: `Bearer ${accessToken}`
});

const _doRefresh = async (deferred: Deferred<boolean>) => {
  let body;
  const response = await fetch(StaticUrls.REFRESH, {
    method: HttpMethod.GET,
    headers: getDefaultHeaders((await Cookies.get(Defaults.REFRESH_TOKEN_COOKIE_KEY)) || '')
  });
  try {
    body = await response.json();
  } catch (error) {}
  if (response.ok) {
    Cookies.set(Defaults.ACCESS_TOKEN_COOKIE_KEY, body.accessToken);
    Cookies.set(Defaults.REFRESH_TOKEN_COOKIE_KEY, body.refreshToken);
    queue.forEach(d => d.resolve(true));
    queue.length = 0;
  } else {
    queue.forEach(d => d.resolve(false));
    queue.length = 0;
  }
};
export const refreshToken = (): Promise<boolean> => {
  const deferred = new Deferred<boolean>();
  queue.push(deferred);
  if (queue.length === 1) {
    _doRefresh(deferred);
  }
  return deferred.promise;
};
