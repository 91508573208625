import { utcToZonedTime, zonedTimeToUtc } from 'date-fns-tz';

export const timeZone = 'America/Los_Angeles';

const dateTimeFormatter = Intl.DateTimeFormat('en-US', {
  timeZone,
  year: 'numeric',
  month: 'short',
  day: 'numeric',
  hour: '2-digit',
  minute: '2-digit',
  timeZoneName: 'short'
});

export const formatDateTime = (date: Date) => {
  return dateTimeFormatter.format(date);
};

export const formatTimezone = (date: Date) => {
  return dateTimeFormatter.format(date);
};

export const formatTime = (date: Date, timeZone?: string) => {
  return Intl.DateTimeFormat('en-US', { timeZone, timeStyle: 'short' }).format(date);
};

export const convertToTz = (date: Date, timeZone: string) => utcToZonedTime(date, timeZone);
export const convertFromTz = (date: Date, timeZone: string) => zonedTimeToUtc(date, timeZone);
