import { DataTableHeadCell } from '@rmwc/data-table';
import styled from '../styled-components';

export const StyledDataTableHeadCell = styled(DataTableHeadCell)`
  font-family: Roboto;
  font-size: 0.8rem !important;
  font-weight: 600 !important;
  text-transform: uppercase;
  letter-spacing: 0.025em !important;
`;
