export const Defaults = {
  ACCESS_TOKEN_COOKIE_KEY: 'accessToken',
  REFRESH_TOKEN_COOKIE_KEY: 'refreshToken',
  LIST_PAGE_SIZE: 10,
  PAGE_LIMIT_OPTIONS: [10, 20, 30, 50],
  MEDIA_BREAKPOINTS: {
    xs: 600,
    sm: 1024,
    md: 1440,
    lg: 1920
  },
  REGEX: {
    url: /^((https?):\/\/)?(www.)?[a-z0-9-]+(\.[a-z]{2,}){1,3}(#?\/?[a-zA-Z0-9#-]+)*\/?(\?[a-zA-Z0-9-_]+=[a-zA-Z0-9-%]+&?)?$/
  }
};
export const API_URL = process.env.REACT_APP_BASE_URL;
export default Defaults;
