import React, { useEffect, useState } from 'react';
import { RouteComponentProps, withRouter } from 'react-router';

import { addHours, addMinutes, startOfDay } from 'date-fns';
import { Form, Formik, FormikActions } from 'formik';
import ReactDatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { Button } from '@rmwc/button';
import { Card } from '@rmwc/card';
import { CircularProgress } from '@rmwc/circular-progress';
import { SimpleDialog } from '@rmwc/dialog';
import * as Yup from 'yup';

import StyledButton from '../../../components/StyledButton';
import TableTitle from '../../../components/TableTitle';
import TextField from '../../../components/TextField';

import { ErrorMessages } from '../../../constants/Strings';
import theme from '../../../constants/Theme';

import CmsSnackbarQueue from '../../../lib/CmsSnackbarQueue';
import { useCreateInterstitial, useDeleteInterstitial, useEditInterstitial, useGetInterstitial } from '../../../lib/api/Interstitial.hooks';

import styled from '../../../styled-components';

import { convertFromTz, convertToTz, formatTime, timeZone } from '../../../utils/date';

type InterstitialEditProps = RouteComponentProps<{ id: string }>;
type InterstitialFormType = {
  id: number;
  description: string;
  url: string;
  startDate: Date;
  endDate: Date;
};

const InterstitialEditSchema = Yup.object().shape({
  description: Yup.string()
    .max(255, ErrorMessages.MAX_CHARACTERS)
    .required(ErrorMessages.REQUIRED_DESCRIPTION),
  url: Yup.string()
    .url('Enter proper URL')
    .required(ErrorMessages.REQUIRED_URL)
    .max(4096, ErrorMessages.MAX_CHARACTERS),
  startDate: Yup.string().required(ErrorMessages.REQUIRED_MESSAGE),
  endDate: Yup.string().required(ErrorMessages.REQUIRED_MESSAGE)
});

const Container = styled.div`
  display: flex;
  flex: 1;
  padding: 20px;
  background-color: #eee;
  flex-direction: column;
`;

const CalendarContainer = styled.div`
  display: flex;
  flex: 1;
  align-content: space-between;
  padding: 10px 0px 20px 0px;
  background-color: #fff;
  flex-direction: row;
  width: 100%;
`;

const CalendarIndividualContainer = styled.div`
  display: block;
  flex: 1;
  width: 100%;
  align-content: center;
  padding: 10px 0px;
  background-color: #fff;
  flex-direction: column;
`;
const TimeContainer = styled.div`
  display: block;
  flex: 1;
  max-width: 400px;
  align-content: center;
  padding: 10px 0px;
  background-color: #fff;
`;

const StyledDropdown = styled.select`
  width: 199px;
  height: 50px;
  background-color: white;
  font-size: 12pt;
  color: black;
  border-color: #aeaeae;
  margin: 0;
  padding: 0 30px 0 10px;
  border-radius: 4px;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  cursor: pointer;
`;

const StyledOption = styled.option``;

const StyledCard = styled(Card)`
  display: flex;
`;

const FormContainer = styled(Form)`
  display: flex;
  flex-direction: column;
  width: 100%;
  flex: 1;
  justify-content: flex-start;
  padding: 5px 20px 20px;
`;

const StyledTextField = styled(TextField)`
  margin-top: 10px;
  margin-bottom: 10px;
`;

const StyledCalendarTextField = styled(TextField)`
  margin: 0px;
`;

const Spinner = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 200px;
  width: 100%;
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: space-between;
`;

const CharactersRemaining = styled.text`
  color: #666666;
  font-size: 10pt;
  height: 20px;
  vertical-align: middle;
  margin-top: 5px;
`;

const Buttons = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center & > * {
    margin: 5px;
  }
`;

const StyledTableTitle = styled(TableTitle)`
  display: flex;
  flex-direction: row;
  margin: 10px 20px 5px;
  padding-top: 10px;
  justify-content: space-between;
  align-items: center;
`;

const StyledTableSubTitle = styled(TableTitle)`
  display: flex;
  flex-direction: row;
  margin: 10px 20px 5px;
  justify-content: space-between;
  align-items: center;
  font-size: 15px;
`;

const DeleteButton = styled(Button)`
  height: 44px !important;
  border-radius: 22px !important;
  &&& {
    color: ${theme.destructiveColor};
  }
`;

const timeOptions = Array.from({ length: 24 })
  .map((_, hour) => Array.from({ length: 4 }).map((_, minute) => ({ hour, minute: minute * 15 })))
  .flat();

function InterstitialEdit({ history, match }: InterstitialEditProps) {
  const interstitialId = match.params.id;
  const id = parseInt(interstitialId);
  const getInterstitial = useGetInterstitial(id);
  const editInterstitial = useEditInterstitial(parseInt(interstitialId));
  const createInterstitial = useCreateInterstitial();
  const deleteInterstitial = useDeleteInterstitial(interstitialId);
  const [dialog, setDialog] = useState(false);

  const initialValues = React.useMemo(
    (): InterstitialFormType => ({
      id: getInterstitial.data?.id ?? 0,
      description: getInterstitial.data?.description ?? '',
      url: getInterstitial.data?.url ?? '',
      startDate: convertToTz(getInterstitial.data ? getInterstitial.data.startDate : new Date(), timeZone),
      endDate: convertToTz(getInterstitial.data ? getInterstitial.data.endDate : new Date(), timeZone)
    }),
    [interstitialId, getInterstitial.data]
  );

  useEffect(() => {
    if (interstitialId === 'new') return;
    getInterstitial.get();
  }, [getInterstitial.get, interstitialId]);

  useEffect(() => {
    if (editInterstitial.data && !editInterstitial.error && !editInterstitial.isLoading) {
      CmsSnackbarQueue.notify({
        title: `Interstitial edited successfully.`,
        actions: [
          {
            label: 'DONE',
            onClick: () => history.push('/cms/interstitial')
          }
        ]
      });
      history.push('/cms/interstitial');
    }
  }, [history, editInterstitial.data, editInterstitial.error, editInterstitial.isLoading]);

  useEffect(() => {
    if (createInterstitial.data && !createInterstitial.error && !createInterstitial.isLoading) {
      CmsSnackbarQueue.notify({
        title: `Interstitial created successfully.`,
        actions: [
          {
            label: 'DONE',
            onClick: () => history.push('/cms/interstitial')
          }
        ]
      });
      history.push('/cms/interstitial');
    }
  }, [history, createInterstitial.data, createInterstitial.error, createInterstitial.isLoading]);

  useEffect(() => {
    if (deleteInterstitial.data && !deleteInterstitial.error && !deleteInterstitial.isLoading) {
      CmsSnackbarQueue.notify({
        title: `Interstitial deleted successfully.`,
        actions: [
          {
            label: 'DONE',
            onClick: () => history.push('/cms/interstitial')
          }
        ]
      });
      history.push('/cms/interstitial');
    }
  }, [history, deleteInterstitial.data, deleteInterstitial.error, deleteInterstitial.isLoading]);

  useEffect(() => {
    if (createInterstitial.data && createInterstitial.error && !createInterstitial.isLoading) {
      CmsSnackbarQueue.notify({
        title: createInterstitial.error.message,
        actions: [
          {
            label: 'DONE',
            onClick: () => history.push('/cms/interstitial')
          }
        ]
      });
    }
  }, [history, createInterstitial.data, createInterstitial.error, createInterstitial.isLoading]);

  useEffect(() => {
    if (editInterstitial.data && editInterstitial.error && !editInterstitial.isLoading) {
      CmsSnackbarQueue.notify({
        title: editInterstitial.error.message,
        actions: [
          {
            label: 'DONE',
            onClick: () => history.push('/cms/interstitial')
          }
        ]
      });
    }
  }, [history, editInterstitial.data, editInterstitial.error, editInterstitial.isLoading]);

  useEffect(() => {
    if (deleteInterstitial.data && deleteInterstitial.error && !deleteInterstitial.isLoading) {
      CmsSnackbarQueue.notify({
        title: deleteInterstitial.error.message,
        actions: [
          {
            label: 'DONE',
            onClick: () => history.push('/cms/interstitial')
          }
        ]
      });
    }
  }, [history, deleteInterstitial.data, deleteInterstitial.error, deleteInterstitial.isLoading]);

  const handleSubmit = async (values: InterstitialFormType, actions: FormikActions<InterstitialFormType>) => {
    actions.setSubmitting(true);

    const startDate = convertFromTz(values.startDate, timeZone)
    const endDate = convertFromTz(values.endDate, timeZone)

    if (interstitialId === 'new') {
      const {id, ...rest} = values;
      await createInterstitial.create({...rest, startDate, endDate});
    } else {
      await editInterstitial.edit({...values, startDate, endDate});
    }

    actions.setSubmitting(false);
  };

  const handleCancel = () => {
    history.goBack();
  };

  const handleDelete = () => {
    deleteInterstitial.interstitialDelete();
  };

  return (
    <Container>
      {getInterstitial.isLoading && (
        <Spinner>
          <CircularProgress size='large' />
        </Spinner>
      )}

      {!getInterstitial.isLoading && (
        <>
          <StyledCard>
            <StyledTableTitle>{interstitialId !== 'new' ? 'Edit Interstitial' : 'Create Interstitial'}</StyledTableTitle>
            <StyledTableSubTitle>Interstitial will be shown at the chosen date and time range in each time zone.</StyledTableSubTitle>
            <Formik {...{ initialValues }} validationSchema={InterstitialEditSchema} validateOnBlur={true} onSubmit={handleSubmit}>
              {({ values, errors, touched, handleChange, handleBlur, isSubmitting }) => (
                <FormContainer>
                  {255 - values.description.length < 20 ? (
                    <CharactersRemaining>{255 - values.description.length} Characters remaining</CharactersRemaining>
                  ) : (
                    <CharactersRemaining />
                  )}
                  <StyledTextField
                    outlined
                    type='text'
                    name='description'
                    label='Description'
                    autoComplete='off'
                    characterCount
                    maxLength={255}
                    onChange={(e: any) => handleChange(e)}
                    onBlur={handleBlur}
                    value={values.description}
                    helpText={{ persistent: true, validationMsg: true, children: touched.description && errors.description }}
                  />
                  {4096 - values.url.length < 20 ? (
                    <CharactersRemaining>{4096 - values.url.length} Characters remaining</CharactersRemaining>
                  ) : (
                    <CharactersRemaining />
                  )}
                  <StyledTextField
                    outlined
                    type='text'
                    name='url'
                    label='URL'
                    autoComplete='off'
                    characterCount
                    maxLength={4096}
                    onChange={(e: any) => handleChange(e)}
                    onBlur={handleBlur}
                    value={values.url}
                    helpText={{ persistent: true, validationMsg: true, children: touched.url && errors.url }}
                  />
                  <CalendarContainer>
                    <CalendarIndividualContainer>
                      <TableTitle style={{ fontSize: '14px' }}>Start Time (PT):</TableTitle>
                      <ReactDatePicker
                        customInput={
                          <StyledCalendarTextField
                            outlined
                            type='text'
                            name='startDate'
                            autoComplete='off'
                            maxLength={10}
                            onBlur={handleBlur}
                            value={values.startDate}
                          />
                        }
                        selected={values.startDate}
                        maxDate={values.endDate}
                        onChange={value => handleChange({ target: { name: 'startDate', value } })}
                      />
                      <TimeContainer>
                        <StyledDropdown
                          name='startDate'
                          value={new Date(values.startDate).toISOString()}
                          onChange={e => {
                            handleChange({ target: { name: 'startDate', value: new Date(e.target.value) } });
                          }}
                        >
                          {timeOptions
                            .map(({ hour, minute }) => addMinutes(addHours(startOfDay(values.startDate), hour), minute))
                            .map((date, index) => (
                              <StyledOption disabled={date.getTime() >= values.endDate.getTime()} key={index} value={date.toISOString()}>
                                {formatTime(date)}
                              </StyledOption>
                            ))}
                        </StyledDropdown>
                      </TimeContainer>
                    </CalendarIndividualContainer>
                    <CalendarIndividualContainer>
                      <TableTitle style={{ fontSize: '14px' }}>End Time (PT):</TableTitle>
                      <ReactDatePicker
                        selected={values.endDate}
                        minDate={values.startDate}
                        customInput={
                          <StyledCalendarTextField
                            outlined
                            type='text'
                            name='endDate'
                            autoComplete='off'
                            maxLength={10}
                            onBlur={handleBlur}
                            value={values.endDate}
                          />
                        }
                        onChange={value => handleChange({ target: { name: 'endDate', value } })}
                      />
                      <TimeContainer>
                        <StyledDropdown
                          name='endDate'
                          value={new Date(values.endDate).toISOString()}
                          onChange={e => {
                            handleChange({ target: { name: 'endDate', value: new Date(e.target.value) } });
                          }}
                        >
                          {timeOptions
                            .map(({ hour, minute }) => addMinutes(addHours(startOfDay(values.endDate), hour), minute))
                            .map((date, index) => (
                              <StyledOption disabled={date.getTime() <= values.startDate.getTime()} key={index} value={date.toISOString()}>
                                {formatTime(date)}
                              </StyledOption>
                            ))}
                        </StyledDropdown>
                      </TimeContainer>
                    </CalendarIndividualContainer>
                  </CalendarContainer>
                  <ButtonContainer>
                    <Buttons>
                      <StyledButton
                        unelevated
                        type='submit'
                        label='Save'
                        disabled={isSubmitting}
                        icon={isSubmitting ? <CircularProgress /> : null}
                      />
                      <StyledButton type='button' label='Cancel' onClick={handleCancel} style={{ marginLeft: 10 }} />
                      {interstitialId !== 'new' && (
                        <DeleteButton type='button' label='Delete' onClick={() => setDialog(true)} style={{ marginLeft: 10 }} />
                      )}
                    </Buttons>
                  </ButtonContainer>
                </FormContainer>
              )}
            </Formik>
          </StyledCard>
        </>
      )}
      <SimpleDialog
        title='Delete Interstitial'
        body='Are you sure you want to delete this interstitial?'
        open={dialog}
        onClose={async e => {
          if (e.detail.action === 'accept') {
            await handleDelete();
          }
          setDialog(false);
        }}
        acceptLabel='Delete'
      />
    </Container>
  );
}

export default withRouter(InterstitialEdit);
