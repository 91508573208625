import { useCallback, useEffect } from 'react';

import Defaults from '../../constants/Defaults';
import { ErrorMessages } from '../../constants/Strings';
import { AccessToken } from '../../types/AccessToken';
import { User } from '../../types/User';
import useUser from '../user/useUser';
import { HttpMethod, HttpStatusCode, removeAccessToken, setAccessToken } from './Api';
import { useApiGet, useApiMutation } from './Api.hooks';
import { useExportData } from '../hooks';

export function useGetCurrentUser() {
  const { data, response, isLoading, error, get } = useApiGet<User | null | undefined>(undefined, 'users/me');
  let errorMessage;
  let returnData = data;

  useEffect(() => {
    if (response && !response.ok) {
      if (response.status === HttpStatusCode.UNAUTHORIZED || response.status === HttpStatusCode.FORBIDDEN) {
        removeAccessToken();
      }
    }
  }, [response]);

  if (response && !response.ok) {
    errorMessage = ErrorMessages.INVALID_SESSION;
    returnData = null;
  }

  return { data: returnData, isLoading, error: error || errorMessage, get };
}

export type LogInDTO = {
  email: string;
  password: string;
};

export type LoginStatus = {
  type: string;
  remainAttempts?: number;
  unlockTime?: number;
};

export function useLogin() {
  const [user, setUser] = useUser();
  const { data, response, isLoading, error, mutate } = useApiMutation<AccessToken | LoginStatus, LogInDTO>(
    null,
    HttpMethod.POST,
    'users/login'
  );

  const loginAttemptResponse = data as LoginStatus;

  useEffect(() => {
    if (response && response.ok && data) {
      const tokenData = data as AccessToken;
      setAccessToken(tokenData.accessToken, tokenData.refreshToken);

      const [, payload] = tokenData.accessToken.split('.');

      const message: any = JSON.parse(window.atob(payload));

      setUser({
        id: message.sub,
        firstName: message.firstName,
        lastName: message.lastName,
        username: message.username,
        verified: message.verified,
        email: '',
        created: '',
        modified: '',
        roles: message.roles
      });
    }
  }, [response, data, setUser]);

  const doLogin = useCallback(
    async (email: string, password: string) => {
      await mutate({ email, password });
    },
    [mutate]
  );

  return { data: data && user, isLoading, error, doLogin, response, loginStatus: loginAttemptResponse };
}

export function useLogout() {
  const [, setUser] = useUser();
  const { response, isLoading, error, mutate } = useApiMutation(null, HttpMethod.POST, 'users/logout');

  useEffect(() => {
    if (response && response.ok) {
      removeAccessToken();
      setUser(null);
    }
  }, [response, setUser]);

  const doLogout = useCallback(async () => {
    await mutate();
  }, [mutate]);

  return { isLoading, error, doLogout };
}

// Example of the most simple implementation
export function useGetUser(userId: string) {
  return useApiGet<User | null>(null, 'users/' + userId);
}

// Example of a more typical implementation
export function useGetUsersByPage() {
  const { get: originalGet, ...rest } = useApiGet<User[]>([], 'users/');

  const get = useCallback(
    async (params: { page?: number; query?: string; sort?: string; limit?: number }) => {
      const defaults = {
        page: 0,
        limit: Defaults.LIST_PAGE_SIZE
      };
      await originalGet({ ...defaults, ...params });
    },
    [originalGet]
  );

  return { ...rest, get };
}

export function useGetUsersCount() {
  const { get: getCount, data: countData, isLoading: getUsersCountLoading, error: getUsersError, ...rest } = useApiGet<number | null>(
    null,
    'users/count'
  );
  return { ...rest, getCount, countData, getUsersCountLoading, getUsersError };
}

export type UserUpdateDTO = {
  firstName: string;
  lastName: string;
  email: string;
};

export function useUpdateUser(userId: string) {
  const { mutate, ...rest } = useApiMutation<User, UserUpdateDTO>(null, HttpMethod.PATCH, 'users/' + userId);

  const update = useCallback(
    async (user: UserUpdateDTO) => {
      await mutate(user);
    },
    [mutate]
  );

  return { ...rest, update };
}

export type UserCreateDTO = {
  firstName: string;
  lastName: string;
  email: string;
  password: string;
};

export function useCreateUser() {
  const { mutate, ...rest } = useApiMutation<User, UserCreateDTO>(null, HttpMethod.POST, 'users/register');

  const create = useCallback(
    async (user: UserCreateDTO) => {
      await mutate(user);
    },
    [mutate]
  );

  return { ...rest, create };
}

export function useDeleteUser(userId: string) {
  const { mutate, ...rest } = useApiMutation<User, {}>(null, HttpMethod.DELETE, 'users/' + userId);

  const userDelete = useCallback(async () => {
    await mutate();
  }, [mutate]);

  return { ...rest, userDelete };
}

export type RolesDTO = {
  id: number;
  name: string;
};

export function useGetAllRoles() {
  return useApiGet<RolesDTO[] | null>([], 'roles');
}

export function useLockUser(userId: string, type: 'lock' | 'unlock') {
  const { mutate, ...rest } = useApiMutation(null, HttpMethod.POST, `users/${userId}/${type}`);

  const lockUser = useCallback(async () => {
    await mutate();
  }, [mutate]);

  return { ...rest, lockUser };
}

export function useExportUsers() {
  const { get, data, response, ...rest } = useApiGet<any>(null, `users/export`, 'text');

  useExportData('users', response, data);

  return { get, ...rest };
}
