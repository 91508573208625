import { useEffect } from 'react';

export const useExportData = (prefix: string, response: Response | null, data: any) => {
  useEffect(() => {
    if (response && response.ok && data) {
      const blob = new Blob([data], { type: 'data:text/csv;charset=utf-8,' });
      const blobURL = window.URL.createObjectURL(blob);

      // Create new tag for download file
      const anchor = document.createElement('a');
      const now = new Date();

      anchor.download = `${prefix}-${now.toLocaleDateString()}.csv`;
      anchor.href = blobURL;
      anchor.dataset.downloadurl = ['text/csv', anchor.download, anchor.href].join(':');
      anchor.click();

      // Remove URL.createObjectURL. The browser should not save the reference to the file.
      setTimeout(() => {
        // For Firefox it is necessary to delay revoking the ObjectURL
        URL.revokeObjectURL(blobURL);
      }, 100);
    }
  }, [response, data]);
};
