const FileService = {
  dataURLToBlob: (dataURL: string) => {
    // convert base64/URLEncoded data component to raw binary data held in a string
    let byteString = '';
    if (dataURL.split(',')[0].indexOf('base64') >= 0) {
      byteString = atob(dataURL.split(',')[1]);
    }

    // separate out the mime component
    const mimeString = dataURL
      .split(',')[0]
      .split(':')[1]
      .split(';')[0];

    // write the bytes of the string to a typed array
    const ia = new Uint8Array(byteString.length);
    for (let i = 0; i < byteString.length; i++) {
      ia[i] = byteString.charCodeAt(i);
    }
    return new Blob([ia], { type: mimeString });
  },
  getBase64: async (file: File) => {
    const dataUrl = (await new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = (event: any) => resolve(event.target.result);
      reader.onerror = error => reject(error);
    })) as string;
    return dataUrl || '';
  },
  getText: async (file: File) => {
    const data = (await new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsText(file);
      reader.onload = (event: any) => resolve(event.target.result);
      reader.onerror = error => reject(error);
    })) as string;
    return data || '';
  },
  loadFileUrl: (fileUrl: string): Promise<any> => {
    return new Promise((resolve, reject) => {
      const file = new Image();
      file.onload = () => {
        resolve(file);
      };
      file.onerror = error => {
        reject(error);
      };
      file.src = fileUrl;
    });
  }
};

export default FileService;
