import React, { useEffect, useState } from 'react';
import { RouteComponentProps, withRouter } from 'react-router';

import { Button } from '@rmwc/button';
import { Card } from '@rmwc/card';
import { CircularProgress } from '@rmwc/circular-progress';
import { SimpleDialog } from '@rmwc/dialog';

import { Form, Formik, FormikActions } from 'formik';
import * as Yup from 'yup';

import { ErrorMessages } from '../../../constants/Strings';
import TextField from '../../../components/TextField';
import Checkbox from '../../../components/Checkbox';
import SelectField from '../../../components/SelectField';
import TableTitle from '../../../components/TableTitle';
import StyledButton from '../../../components/StyledButton';
import {
  useGetUser,
  useUpdateUser,
  useCreateUser,
  useDeleteUser,
  useGetAllRoles,
  RolesDTO,
  useLockUser
} from '../../../lib/api/User.hooks';
import CmsSnackbarQueue from '../../../lib/CmsSnackbarQueue';
import styled from '../../../styled-components';
import theme from '../../../constants/Theme';

type UserEditProps = RouteComponentProps<{ id: string }>;
type UserFormType = {
  id: number | string;
  firstName: string;
  lastName: string;
  username: string;
  verified: boolean;
  email: string;
  password?: string;
  roles?: RolesDTO[];
  locked?: boolean;
  emailOptIn?: boolean;
  smsOptIn?: boolean;
};

const UserEditSchema = Yup.object().shape({
  email: Yup.string()
    .email(ErrorMessages.INVALID_EMAIL)
    .required(ErrorMessages.REQUIRED_EMAIL)
});

const UserCreateSchema = Yup.object().shape({
  email: Yup.string()
    .email(ErrorMessages.INVALID_EMAIL)
    .required(ErrorMessages.REQUIRED_EMAIL),
  password: Yup.string().required(ErrorMessages.REQUIRED_PASSWORD)
});

const Container = styled.div`
  padding: 20px;
  background-color: #eee;
  flex: 1;
  display: flex;
  flex-direction: column;
`;

const StyledTableTitle = styled(TableTitle)`
  display: flex;
  flex-direction: row;
  margin: 10px 20px 5px;
  justify-content: space-between;
  align-items: center;
`;

const StyledCard = styled(Card)`
  display: flex;
`;

const Spinner = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 200px;
`;

const FormContainer = styled(Form)`
  width: 100%;
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  padding: 20px;
`;

const StyledTextField = styled(TextField)`
  margin-top: 10px;
  margin-bottom: 10px;
  .mdc-text-field:not(.mdc-text-field--disabled) + .mdc-text-field-helper-line .mdc-text-field-helper-text {
    color: red !important;
  }
`;

const StyledCheckbox = styled(Checkbox)`
  height: 44px !important;
`;

const ButtonContainer = styled.div`
  display: fex;
  justify-content: space-between;
`;

const Buttons = styled.div`
  display: flex;
  justify-content: flex-start;

  & > * {
    margin: 5px;
  }
`;

const DeleteButton = styled(Button)`
  height: 44px !important;
  border-radius: 22px !important;
  &&& {
    color: ${theme.destructiveColor};
  }
`;

const SelectContainer = styled.div`
  margin-top: 10px;
  margin-bottom: 20px;
`;

function UserEdit(props: UserEditProps) {
  const { history, match } = props;
  const userId = match.params.id;
  const getUser = useGetUser(userId);
  const { update: updateUser, isLoading: updateIsLoading, data: updateData, error: updateError } = useUpdateUser(userId);
  const { get } = getUser;
  const { create: createUser, isLoading: createIsLoading, data: createData, error: createError } = useCreateUser();
  const { userDelete, isLoading: deleteIsLoading, data: deleteData, error: deleteError } = useDeleteUser(userId);
  const getAllRoles = useGetAllRoles();
  const [dialog, setDialog] = useState(false);
  const { lockUser } = useLockUser(userId, 'lock');
  const { lockUser: unlockUser } = useLockUser(userId, 'unlock');

  useEffect(() => {
    if (userId !== 'new') {
      get();
    }
  }, [get, userId]);

  useEffect(() => {
    getAllRoles.get();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getAllRoles.get]);

  useEffect(() => {
    if (createError && createError.message === 'The email is already in use.')
      CmsSnackbarQueue.notify({
        title: 'The email is already in use.',
        actions: [
          {
            label: 'Close'
          }
        ]
      });
    else if (updateError && updateError.message === 'The email is already in use.') {
      CmsSnackbarQueue.notify({
        title: 'The email is already in use.',
        actions: [
          {
            label: 'Close'
          }
        ]
      });
    } else {
      const message = (createError !== null && createError.message) || (updateError !== null && updateError.message) || '';
      if (message !== '') {
        CmsSnackbarQueue.notify({
          title: `Error Message: ${message}`,
          actions: [
            {
              label: 'Close'
            }
          ]
        });
      }
    }
  }, [createError, updateError]);

  useEffect(() => {
    if (updateData && !updateError && !updateIsLoading) {
      CmsSnackbarQueue.notify({
        title: `${updateData.firstName} ${updateData.lastName} saved successfully.`,
        actions: [
          {
            label: 'VIEW',
            onClick: () => history.push('/cms/users/' + updateData!.id + '/view')
          }
        ]
      });

      // TODO this causes a warning. Fix warning.
      history.push('/cms/users');
    } else if (createData && !createError && !createIsLoading) {
      CmsSnackbarQueue.notify({
        title: `${createData.firstName} ${createData.lastName} created successfully.`,
        actions: [
          {
            label: 'VIEW',
            onClick: () => history.push('/cms/users/' + createData!.id + '/view')
          }
        ]
      });
      history.push('/cms/users');
    } else if (deleteData && !deleteError && !deleteIsLoading) {
      CmsSnackbarQueue.notify({
        title: `${deleteData.firstName} ${deleteData.lastName} deleted successfully.`
      });
      history.push('/cms/users');
    }
  }, [
    history,
    updateData,
    updateError,
    updateIsLoading,
    createData,
    createError,
    createIsLoading,
    deleteData,
    deleteError,
    deleteIsLoading
  ]);

  let initialValues: UserFormType | null;
  let ready = false;

  if (userId === 'new') {
    initialValues = { id: 'new', firstName: '', lastName: '', username: '', verified: false, email: '', password: '', locked: false };
    ready = true;
  } else {
    initialValues = getUser.data;
    ready = !getUser.isLoading;
  }

  const handleSubmit = async (values: UserFormType, actions: FormikActions<UserFormType>) => {
    actions.setSubmitting(true);

    if (userId === 'new') {
      const newUser = {
        firstName: values.firstName,
        lastName: values.lastName,
        username: values.username,
        verified: values.verified,
        email: values.email,
        password: values.password!,
        roles: values.roles
      };
      await createUser(newUser);
    } else {
      const userObj = {
        firstName: values.firstName,
        lastName: values.lastName,
        username: values.username,
        verified: values.verified,
        email: values.email,
        roles: values.roles
      };
      await updateUser(userObj);
    }
    if (initialValues && !initialValues.locked && values.locked) {
      await lockUser();
    } else if (initialValues && initialValues.locked && !values.locked) {
      await unlockUser();
    }
    actions.setSubmitting(false);
  };

  const handleDelete = async () => {
    await userDelete();
    history.push('/cms/users');
  };

  const handleCancel = () => {
    history.goBack();
  };

  if (userId === 'new') {
    initialValues = {
      id: 'new',
      firstName: '',
      lastName: '',
      username: '',
      verified: false,
      email: '',
      password: '',
      emailOptIn: false,
      smsOptIn: false
    };
    ready = true;
  } else {
    const userObj = getUser.data;
    if (userObj) {
      const checkObj = {
        ...userObj,
        smsOptIn: userObj.marketOptIn === 'Phone' || userObj.marketOptIn === 'All',
        emailOptIn: userObj.marketOptIn === 'Email' || userObj.marketOptIn === 'All'
      };
      initialValues = checkObj;
    }
    ready = !getUser.isLoading;
  }

  return (
    <Container>
      <StyledCard>
        <StyledTableTitle>Users</StyledTableTitle>
        {!ready && (
          <Spinner>
            <CircularProgress size='large' />
          </Spinner>
        )}
        {ready && initialValues && (
          <Formik
            initialValues={initialValues}
            validationSchema={userId !== 'new' ? UserEditSchema : UserCreateSchema}
            validateOnBlur={true}
            onSubmit={handleSubmit}
          >
            {({ values, errors, touched, handleChange, handleBlur, isSubmitting, setFieldValue }) => (
              <FormContainer>
                <StyledTextField outlined name='id' label='ID' value={values.id} disabled />
                <StyledTextField
                  outlined
                  type='text'
                  name='firstName'
                  label='First Name'
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.firstName ? values.firstName : ''}
                  helpText={{ persistent: true, validationMsg: true, children: touched.firstName && errors.firstName }}
                />
                <StyledTextField
                  outlined
                  type='text'
                  name='lastName'
                  label='Last Name'
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.lastName ? values.lastName : ''}
                  helpText={{ persistent: true, validationMsg: true, children: touched.lastName && errors.lastName }}
                />
                <StyledTextField
                  outlined
                  type='text'
                  name='username'
                  label='Username'
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.username}
                  helpText={{ persistent: true, validationMsg: true, children: touched.username && errors.username }}
                />
                <StyledTextField
                  outlined
                  type='email'
                  name='email'
                  label='Email'
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.email}
                  helpText={{ persistent: true, validationMsg: true, children: touched.email && errors.email }}
                />
                <StyledCheckbox name='verified' label='Verified' onChange={handleChange} onBlur={handleBlur} checked={values.verified} />
                <StyledCheckbox name='locked' label='Locked' onChange={handleChange} onBlur={handleBlur} checked={values.locked} />
                <StyledCheckbox
                  disabled
                  name='emailOptIn'
                  label='Email'
                  onChange={handleChange}
                  onBlur={handleBlur}
                  checked={values.emailOptIn}
                />
                <StyledCheckbox
                  disabled
                  name='smsOptIn'
                  label='SMS'
                  onChange={handleChange}
                  onBlur={handleBlur}
                  checked={values.smsOptIn}
                />
                {userId === 'new' && (
                  <StyledTextField
                    outlined
                    type='password'
                    name='password'
                    label='Password'
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.password}
                    helpText={{ persistent: true, validationMsg: true, children: touched.password && errors.password }}
                  />
                )}
                <SelectContainer>
                  <SelectField
                    isMulti={true}
                    label='Select Roles'
                    defaultValue={values.roles}
                    name='user-roles'
                    options={getAllRoles.data}
                    onChange={(e: RolesDTO) => setFieldValue('roles', e)}
                    placeholder='Select roles'
                    changeOptionLabel='name'
                    changeOptionValue='id'
                  />
                </SelectContainer>
                <ButtonContainer>
                  <Buttons>
                    <StyledButton
                      unelevated
                      type='submit'
                      label='Save'
                      disabled={isSubmitting}
                      icon={isSubmitting ? <CircularProgress /> : null}
                    />
                    <StyledButton type='button' label='Cancel' onClick={handleCancel} />
                  </Buttons>
                  {userId !== 'new' && <DeleteButton type='button' label='Delete' onClick={() => setDialog(true)} />}
                </ButtonContainer>
              </FormContainer>
            )}
          </Formik>
        )}
      </StyledCard>
      <SimpleDialog
        title='Delete User'
        body='Are you sure you want to delete this user?'
        open={dialog}
        onClose={async e => {
          if (e.detail.action === 'accept') {
            await handleDelete();
          }
          setDialog(false);
        }}
        acceptLabel='Delete'
      />
    </Container>
  );
}

export default withRouter(UserEdit);
