import { useApiGet } from './Api.hooks';

import { HeatProfiles } from '../../types/HeatProfiles';

import { useCallback } from 'react';

export function useGetHeatProfiles(userId: string) {
  const { get: originalGet, ...rest } = useApiGet<HeatProfiles[]>([], `users/${userId}/heat-profiles`);

  const get = useCallback(async () => {
    await originalGet();
  }, [originalGet]);

  return { ...rest, get };
}
