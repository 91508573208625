import { useCallback } from 'react';

import Defaults from '../../constants/Defaults';
import { DeviceLogs } from '../../types/DeviceLogs';
import { Device } from '../../types/Device';
import { useApiGet } from './Api.hooks';
import { useExportData } from '../hooks';

export function useGetDevice(deviceId: string) {
  return useApiGet<Device | null>(null, 'peak-devices/by-id/' + deviceId);
}

export function useGetDevicesByPage() {
  const { get: originalGet, ...rest } = useApiGet<Device[]>([], 'peak-devices/');

  const get = useCallback(
    async (params: { page?: number; query?: string; sort?: string; limit?: number; userId?: number }) => {
      const defaults = {
        page: 0,
        limit: Defaults.LIST_PAGE_SIZE
      };
      await originalGet({ ...defaults, ...params });
    },
    [originalGet]
  );
  return { ...rest, get };
}

export function useGetDevicesCount() {
  const { get: getCount, data: countData, isLoading: getDeviceCountLoading, error: getDeviceError, ...rest } = useApiGet<number | null>(
    null,
    'peak-devices/count'
  );
  return { ...rest, getCount, countData, getDeviceCountLoading, getDeviceError };
}

export function useGetLogsCount(deviceId: string) {
  return useApiGet<number | null>(null, `peak-devices/${deviceId}/logs/count`);
}

export function useGetDeviceLogs(deviceId: string) {
  const { get: originalGet, ...rest } = useApiGet<DeviceLogs[]>([], `peak-devices/${deviceId}/logs`);

  const get = useCallback(
    async (params: { page?: number; query?: string; sort?: string; limit?: number }) => {
      const defaults = {
        page: 0,
        limit: Defaults.LIST_PAGE_SIZE
      };
      await originalGet({ ...defaults, ...params });
    },
    [originalGet]
  );

  return { ...rest, get };
}

export function useGetUserDevices(userId: string) {
  return useApiGet<Device[] | null>(null, `users/${userId}/peak-devices`);
}

export interface FirmwareCount {
  firmware: string; //firmware
  units: number;
}

export function useGetFirmwareCount() {
  return useApiGet<FirmwareCount[]>([], `peak-devices/firmware-count`);
}

export function useExportPeakDevices() {
  const { get, data, response, ...rest } = useApiGet<any>(null, `peak-devices/export`, 'text');

  useExportData('devices', response, data);

  return { get, ...rest };
}
