import { createGlobalStyle } from '../styled-components';

const baseColors = {
  atomic: '#3F4D55',
  blueWhale: '#002F49',
  black: '#000000',
  emerald: '#3AD47C',
  ghostWhite: '#dcdce0',
  gainsboro: '#E1E1E1',
  grey: '#686868',
  lightGrey: '#ACACAC',
  matterhorn: '#565656',
  pelorous: '#2388C1',
  red: '#FF0000',
  white: '#FFFFFF',
  destructiveColor: '#FF0000'
};

const appBaseColors = {
  appBar: baseColors.blueWhale,
  primary: baseColors.black,
  secondary: baseColors.white
};

const componentStateColors = {
  activeNavLink: baseColors.blueWhale,
  signInButtonBackground: baseColors.emerald,
  signInFormBackground: baseColors.blueWhale,
  inputBackground: baseColors.gainsboro,
  inputLabel: baseColors.pelorous,
  inputValid: baseColors.emerald,
  inputInvalid: baseColors.red
};

const theme = {
  ...baseColors,
  ...appBaseColors,
  ...componentStateColors
};

/**
 * The floating label color bug as of today:
 * https://bitbucket.org/isbx/boilerplates-cms/pull-requests/2/feature-bplt-66-cms-set-up-theming/diff#comment-102555670
 * https://github.com/material-components/material-components-web/issues/2718
 */

export const GlobalThemeStyle = createGlobalStyle`
  ${({ theme }) => `
    :root {
      --mdc-theme-primary: ${theme.primary};
      --mdc-theme-secondary: ${theme.secondary};
      --mdc-theme-error: ${theme.destructiveColor};

      .mdc-text-field--focused:not(.mdc-text-field--disabled) .mdc-floating-label {
        color: var(--mdc-theme-primary);
      }
    }
  `}
`;

export type Theme = Readonly<typeof theme>;
export default theme;
