import React, { useEffect, useState } from 'react';
import { RouteComponentProps, withRouter } from 'react-router';
import { Button } from '@rmwc/button';
import { CircularProgress } from '@rmwc/circular-progress';
import { useQueryParam, NumberParam, StringParam } from 'use-query-params';

import styled from '../../../styled-components';
import { useGetDevice } from '../../../lib/api/Device.hooks';
import TableTitle from '../../../components/TableTitle';
import LogRow from '../../../components/LogRow';
import { useGetDeviceLogs, useGetLogsCount } from '../../../lib/api/Device.hooks';
import Pagination from '../../../components/Pagination';
import Defaults from '../../../constants/Defaults';

type DeviceViewProps = RouteComponentProps<{ id: string }>;

const Container = styled.div`
  padding: 20px;
  background-color: #eee;
  flex: 1;
  display: flex;
  flex-direction: column;
`;

const DeviceTitleText = styled.p`
  color: #919191;
  font-size: 9pt;
  padding: 0 0 5px 0;
  font-size: 0.75rem;
  font-weight: 500;
  margin: 0;
`;

const StyledCard = styled.div`
  border-radius: 4px;
  background-color: #fff;
  background-color: #fff;
  background-color: var(--mdc-theme-surface, #fff);
  box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  margin-bottom: 20px;
`;

const StyledPagination = styled(Pagination)`
  align-self: flex-end;
`;

const Spinner = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 200px;
`;

const DeviceInfoContainer = styled.div`
  width: 100%;
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  padding: 15px;
`;

const EmptyTableText = styled.p`
  text-align: center;
`;

const ButtonContainer = styled.div`
  display: fex;
  justify-content: space-between;
`;

const Buttons = styled.div`
  display: flex;
  justify-content: flex-start;

  & > * {
    margin: 5px;
  }
`;

const StyledDeviceDataRow = styled.div`
  border-radius: 5px;
  border: 1px solid lightgrey;
  margin: 0 5px 15px;
  display: flex;
  flex: 1;
  align-items: space-around;
  flex-direction: column;
  padding: 15px;
`;

const StyledLogHeader = styled.div`
  background-color: white;
  border-bottom: 1px solid lightgrey;
  display: flex;
  flex-direction: row;
  width: 100%;
  padding: 15px 0 15px 15px;
`;

const LogTableHeaderTextLarge = styled.p`
  color: #919191;
  font-size: 9pt;
  padding: 0 0 5px 0;
  font-size: 0.75rem;
  font-weight: 500;
  margin: 0;
  flex: 1;
  text-align: left;
`;

const LogTableHeaderTextSmall = styled.p`
  color: #919191;
  font-size: 9pt;
  padding: 0 0 5px 0;
  font-size: 0.75rem;
  font-weight: 500;
  margin: 0;
  flex: 0.5;
  text-align: left;
`;

const LogContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  background-color: white;
  border-radius: 4px;
  background-color: #fff;
  background-color: #fff;
  background-color: var(--mdc-theme-surface, #fff);
  box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
  overflow: hidden;
`;

const StyledDeviceRow = styled.div`
  display: flex;
  align-items: space-between;
  flex-direction: row;
  width: 100%;
`;

const StyledTableTitle = styled(TableTitle)`
  display: flex;
  flex-direction: row;
  margin: 10px 20px 5px;
  justify-content: space-between;
  align-items: center;
`;

function DeviceView(props: DeviceViewProps) {
  const { history, match } = props;
  const deviceId = match.params.id;
  const { data, isLoading, error, get } = useGetDeviceLogs(deviceId);
  const getDevice = useGetDevice(deviceId);

  const getLogsCount = useGetLogsCount(deviceId);
  const getLogsCountGetter = getLogsCount.get;
  const values = getDevice.data;
  const ready = !getDevice.isLoading;
  const [page, setPage] = useQueryParam('page', NumberParam);
  const limitQuery = useQueryParam('limit', NumberParam);
  const [limit, setLimit] = useState(Number(limitQuery) || Defaults.LIST_PAGE_SIZE);
  const [prior] = useQueryParam('prior', StringParam);

  useEffect(() => {
    get({
      page: Number(page) || 0,
      limit: Number(limit) || Defaults.LIST_PAGE_SIZE
    });
    getLogsCountGetter();
  }, [get, page, limit, getLogsCountGetter]);

  useEffect(() => {
    getDevice.get();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getDevice.get]);

  const formatDate = (current_datetime: string) => {
    const date = new Date(current_datetime);
    return date.getMonth() + 1 + '-' + date.getDate() + '-' + date.getFullYear();
  };

  const setNewPage = (page: number) => {
    page = page - 1;
    setPage(page);
  };

  const handleCancel = () => {
    if (prior) {
      history.push(String(prior));
    } else {
      history.push('/cms/devices/');
    }
  };

  return (
    <Container>
      <StyledCard>
        <StyledTableTitle>Device</StyledTableTitle>
        {!ready && (
          <Spinner>
            <CircularProgress size='large' />
          </Spinner>
        )}
        {ready && values && (
          <DeviceInfoContainer>
            <StyledDeviceRow>
              <StyledDeviceDataRow>
                <DeviceTitleText>ID</DeviceTitleText> {values.id}
              </StyledDeviceDataRow>
              <StyledDeviceDataRow>
                <DeviceTitleText> Name</DeviceTitleText>
                {values.name}
              </StyledDeviceDataRow>
              <StyledDeviceDataRow>
                <DeviceTitleText> Serial Number</DeviceTitleText>
                {values.serialNumber}
              </StyledDeviceDataRow>
              <StyledDeviceDataRow>
                <DeviceTitleText> Model</DeviceTitleText>
                {values.model}
              </StyledDeviceDataRow>
              <StyledDeviceDataRow>
                <DeviceTitleText> Date of Birth</DeviceTitleText>
                {formatDate(values.dob)}
              </StyledDeviceDataRow>
            </StyledDeviceRow>
            <StyledDeviceRow>
              <StyledDeviceDataRow>
                <DeviceTitleText> Hit Count</DeviceTitleText>
                {values.hitCount}
              </StyledDeviceDataRow>
              <StyledDeviceDataRow>
                <DeviceTitleText> Total Runtime</DeviceTitleText>
                {values.totalRuntime}
              </StyledDeviceDataRow>
              <StyledDeviceDataRow>
                <DeviceTitleText> Battery Capacity</DeviceTitleText>
                {values.batteryCapacity}
              </StyledDeviceDataRow>
              <StyledDeviceDataRow>
                <DeviceTitleText> Firmware</DeviceTitleText>
                {values.firmware}
              </StyledDeviceDataRow>
              <StyledDeviceDataRow>
                <DeviceTitleText> Git Hash</DeviceTitleText>
                {values.gitHash}
              </StyledDeviceDataRow>
            </StyledDeviceRow>
            <ButtonContainer>
              <Buttons>
                <Button theme='secondary' type='button' label='Back' onClick={handleCancel} />
              </Buttons>
            </ButtonContainer>
          </DeviceInfoContainer>
        )}
      </StyledCard>

      <LogContainer>
        <StyledTableTitle>Logs</StyledTableTitle>
        <StyledLogHeader>
          <LogTableHeaderTextSmall>Type</LogTableHeaderTextSmall>
          <LogTableHeaderTextLarge>State</LogTableHeaderTextLarge>
          <LogTableHeaderTextLarge>Timestamp</LogTableHeaderTextLarge>
          <LogTableHeaderTextLarge>Details</LogTableHeaderTextLarge>
        </StyledLogHeader>
        {!isLoading && !error && data && data.map((row, i) => <LogRow key={i} row={row} />)}
        {data.length !== 0 && (
          <StyledPagination
            current={Number(page) + 1 || 1}
            total={Math.ceil((getLogsCount.data || 0) / Number(limit))}
            currentLimit={Number(limit)}
            onLimitChange={(limit: number) => setLimit(limit)}
            onPageChange={(page: number) => setNewPage(page)}
          />
        )}
        {data.length === 0 && <EmptyTableText>No Error Logs</EmptyTableText>}
      </LogContainer>
    </Container>
  );
}

export default withRouter(DeviceView);
