import React, { useEffect, useState } from 'react';
import { Route } from 'react-router';
import { RouteComponentProps, withRouter } from 'react-router-dom';

import { Button } from '@rmwc/button';
import { MenuItem, SimpleMenu } from '@rmwc/menu';
import { TopAppBar, TopAppBarRow, TopAppBarSection, TopAppBarNavigationIcon, TopAppBarFixedAdjust } from '@rmwc/top-app-bar';

import { useMedia } from '../lib/media/media.context';
import styled from '../styled-components';
import { useLogout } from '../lib/api/User.hooks';
import useUser from '../lib/user/useUser';

import NavigationDrawer from './cms/NavigationDrawer';
import CMSRouteSections from './cms/route-sections';
import theme from '../constants/Theme';
import { default as logo } from '../assets/images/logo.png';

type CmsContainerProps = RouteComponentProps;

const Container = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
`;

const StyledTopAppBar = styled(TopAppBar)`
  &&& {
    z-index: 10;
    background-color: white;
    padding: 0 10px;
    box-shadow: 0 2px 5px ${theme.ghostWhite};
  }
`;

const Image = styled.img`
   {
    margin-left: 22;
  }
`;

const Content = styled.div`
  flex: 1;
  display: flex;
`;

function CmsContainer(props: CmsContainerProps) {
  const { history } = props;

  const mediaBreakpoint = useMedia();
  const [open, setOpen] = useState(false);
  const { doLogout } = useLogout();
  const [user] = useUser();

  useEffect(() => {
    if (!user) {
      history.push('/login');
    }
  }, [history, user]);

  const userLabel = user && user.firstName ? user.firstName : user && user.email ? user.email : '';

  return (
    <Container>
      <StyledTopAppBar fixed={true}>
        <TopAppBarRow>
          <TopAppBarSection alignStart>
            {mediaBreakpoint.xs && (
              <TopAppBarNavigationIcon icon='menu' onClick={() => setOpen(!open)} style={{ color: theme.black, marginRight: 10 }} />
            )}
            <Image src={logo} alt='logo' width={113} height={32} />
          </TopAppBarSection>
          <TopAppBarSection alignEnd>
            <SimpleMenu handle={<Button label={userLabel} trailingIcon='arrow_drop_down' />} anchorCorner='bottomLeft'>
              <MenuItem onClick={doLogout}>Log Out</MenuItem>
            </SimpleMenu>
          </TopAppBarSection>
        </TopAppBarRow>
      </StyledTopAppBar>
      <TopAppBarFixedAdjust />

      <Content>
        <NavigationDrawer
          modal={mediaBreakpoint.xs}
          open={!mediaBreakpoint.xs || open}
          onClose={() => setOpen(false)}
          sections={CMSRouteSections}
        />
        {CMSRouteSections.map(section => (
          <Route key={section.path} path={section.path} component={section.component} />
        ))}
      </Content>
    </Container>
  );
}

export default withRouter(CmsContainer);
