import React from 'react';
import { Route, Switch } from 'react-router';

import MessageList from './MessageList';
import MessageCreate from './MessageCreate';

function MessageModule() {
  return (
    <Switch>
      <Route exact path='/cms/messages/' component={MessageList} />
      <Route exact path='/cms/messages/new' component={MessageCreate} />
    </Switch>
  );
}

export default MessageModule;
