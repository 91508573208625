import React from 'react';
import { Route, Switch } from 'react-router';
import InterstitialList from './InterstitialList';
import InterstitialEdit from './InterstitialEdit';

function InterstitialModule() {
  return (
    <Switch>
      <Route exact path='/cms/interstitial/' component={InterstitialList} />
      <Route exact path='/cms/interstitial/:id' component={InterstitialEdit} />
    </Switch>
  );
}

export default InterstitialModule;
