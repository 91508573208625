import React, { Fragment, useCallback, useEffect, useState, useRef } from 'react';
import { RouteComponentProps, withRouter } from 'react-router';
import { CircularProgress } from '@rmwc/circular-progress';
import { Icon } from '@rmwc/icon';
import { Form, Formik, FormikActions } from 'formik';
import * as Yup from 'yup';

import { DateRangeType, Ota, OtaRequestDTO, ModelOptions, ModelsListType } from '../../../types/Ota';
import theme from '../../../constants/Theme';
import { API_URL } from '../../../constants/Defaults';
import { ErrorMessages } from '../../../constants/Strings';
import FileService from '../../../lib/services/FileService';
import CmsSnackbarQueue from '../../../lib/CmsSnackbarQueue';
import { useGetOta, useCreateOta, useUpdateOta, useExportOtaPreview, useExportOtaDownload } from '../../../lib/api/Ota.hooks';

import styled from '../../../styled-components';
import SelectField from '../../../components/SelectField';
import TextField from '../../../components/TextField';
import TableTitle from '../../../components/TableTitle';
import StyledButton from '../../../components/StyledButton';
import { getAccessToken } from '../../../lib/api/Api';

type OtaEditProps = RouteComponentProps<{ id: string }>;

type BlobWithName = {
  name?: string;
} & Blob;

type ModelOptionsInput = {
  name: string;
};

type ModelsListTypeInput = {
  id: string;
  name: string;
};

const Container = styled.div`
  display: grid;
  grid-template-columns: 7fr 3fr;
  grid-gap: 15px;
  padding: 20px;
  background-color: #eee;
  width: 100%;
  flex-direction: column;
  align-items: stretch;
`;

const CardContainer = styled.div`
  display: flex;
  align-items: stretch;
  flex-direction: column;
  border-radius: 10px;
  overflow: hidden;
  background-color: white;
`;

const Spinner = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 200px;
  width: 100%;
`;

const OtaInfoWrapper = styled.div`
  width: 100%;
  padding: 25px;
  padding-top: 0;
  overflow: auto;
  align-items: stretch;
  flex-direction: column;
  border-radius: 10px;
  overflow: hidden;
`;

const FullWidthBar = styled.div`
  display: flex;
  flex-direction: row;
  margin: 32px 25px;
  margin-bottom: 25px;
  justify-content: space-between;
  align-items: center;
`;

const StyledInputContainer = styled.div`
  border-radius: 5px;
  border: 1px solid #c2c2c2;
  display: flex;
  justify-content: space-between;
  padding: 10px;
  height: auto;
  align-items: center;
  min-height: 60px;
`;

const StyledRepeatingRow = styled.div`
  display: grid;
  grid-gap: 10px;
  grid-template-columns: auto auto auto;
  width: 100%;
  margin-bottom: 10px;
`;

const StyledTextField = styled(TextField)`
  width: 100%;
  .mdc-text-field:not(.mdc-text-field--disabled) + .mdc-text-field-helper-line .mdc-text-field-helper-text {
    color: red !important;
  }
`;

const StyledLabel = styled.p`
  margin-top: 0;
  margin-bottom: 5px;
  font-family: Roboto;
  font-weight: 500;
  text-transform: uppercase;
  letter-spacing: 0.025em;
  font-size: 14px;
`;

const FormGroup = styled.div`
  margin-bottom: 25px;
`;

const StyledRepeatingColumn = styled.div``;

const StyledRepeatingTextField = styled(TextField)`
  width: 100%;
  margin-top: 5px;
  .mdc-text-field:not(.mdc-text-field--disabled) + .mdc-text-field-helper-line .mdc-text-field-helper-text {
    color: red !important;
  }
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: center;
`;

const StyledMediumLabel = styled.p`
  margin-top: 0;
  margin-bottom: 10px;
  font-family: Roboto;
  font-weight: 500;
  text-transform: uppercase;
  letter-spacing: 0.025em;
  font-size: 18px;
`;

const StyledRepeatingFormWrapper = styled.div``;

const StyledRepeatingFormHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 15px 0px;
  border-bottom: 1px solid lightgrey;
`;

const StyledRepeatingFormContent = styled.div`
  margin-bottom: 25px;
`;

const StyledRepeatingFormItem = styled.div`
  border-radius: 10px;
  border: 1px solid lightgrey;
  margin: 25px 0 0 0;
  display: flex;
  align-items: space-around;
  flex-direction: column;
  padding: 20px;
  height: auto;
  min-height: 60px;
`;

const StyledRepeatingActionRow = styled.div`
  display: flex;
  justify-content: space-between;
`;

const StyledAddIcon = styled(Icon)`
  cursor: pointer;
  font-size: 28px;
`;

const StyledCancelIcon = styled(Icon)`
  cursor: pointer;
  color: grey;
  margin-right: -5px;
  margin-top: -5px;
`;

const OtaSchema = Yup.object().shape({
  version: Yup.string().required(ErrorMessages.REQUIRED_VERSION),
  releaseNotes: Yup.string().required(ErrorMessages.REQUIRED_RELEASENOTES),
  rolloutPercentage: Yup.number().required(ErrorMessages.REQUIRED_PERCENTAGE)
});

function OtaEdit(props: OtaEditProps) {
  const { history, match } = props;
  const otaId = match.params.id;
  const hiddenCsvFileInput = useRef<HTMLInputElement>(null);
  const hiddenFirmwareFileInput = useRef<HTMLInputElement>(null);
  const [firmwareFileName, setFirmwareFileName] = useState('');
  const [firmwareFileData, setFirmwareFileData] = useState<BlobWithName | null>(null);
  const { get: exportDownload } = useExportOtaDownload(otaId);
  const [csvFileData, setCsvFileData] = useState<BlobWithName | null>(null);
  const [mediaUploaded, setMediaUploaded] = useState(false); //check if a new file was uploaded
  const [models, setModels] = useState<string[] | null>(null);
  const getOta = useGetOta(otaId);
  const otaData = getOta.data;

  const [dateRangeFields, setDateRangeFields] = useState<DateRangeType[]>([]);
  const [rolloutPercentage, setRolloutPercentage] = useState(100);
  const [version, setVersion] = useState<string | null>(null);
  const [releaseNotes, setReleaseNotes] = useState<string | null>(null);

  const { create, error: createError, isLoading: createIsLoading, data: createData } = useCreateOta();
  const { update, error: updateError, isLoading: updateIsLoading, data: updateData } = useUpdateOta(otaId);
  const [isLoading, setIsLoading] = useState(false);
  const [fileUrl, setFileUrl] = useState<string | null>(null);

  const modelListTypeOptions = [{ id: '0', name: 'none' }, { id: '1', name: 'all' }, { id: '2', name: 'custom' }];
  const [modelsListType, setModelsListType] = useState(modelListTypeOptions[1]);
  const getInitialValues = (otaData: Ota | null) => {
    if (otaData) {
      const { models } = otaData;
      if (models) {
        if (models.length > 0) {
          otaData.modelsListType = modelListTypeOptions[2];
          const mapList = models.map(model => {
            return { name: model };
          });
          otaData.modelOptions = mapList;
        } else {
          otaData.modelsListType = modelListTypeOptions[0];
        }
      } else {
        otaData.modelsListType = modelListTypeOptions[1];
      }
    }
    return otaData;
  };

  let initialValues;
  let ready = false;
  if (otaId === 'new') {
    initialValues = {
      releaseNotes: '',
      version: '',
      rolloutPercentage: 100,
      dateRange: [],
      currentQty: 0,
      otaTracking: {
        attempts: 0,
        devicesRollout: 0,
        devicesRunning: 0,
        failures: 0,
        successes: 0,
        devicesTargeted: 0
      },
      modelsListType: modelListTypeOptions[1],
      modelOptions: undefined
    };
    ready = true;
  } else {
    initialValues = getInitialValues(getOta.data);
    ready = !getOta.isLoading;
  }

  useEffect(() => {
    if (otaId !== 'new') {
      getOta.get();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getOta.get, otaId]);

  useEffect(() => {
    if (createData && !createError && !createIsLoading) {
      CmsSnackbarQueue.notify({
        title: `OTA file was uploaded successfully.`,
        actions: [
          {
            label: 'VIEW',
            onClick: () => history.push('/cms/ota/' + createData.id)
          }
        ]
      });
      history.push('/cms/ota');
      setIsLoading(false);
    } else if (updateData && !updateError && !updateIsLoading) {
      CmsSnackbarQueue.notify({
        title: `OTA file was uploaded successfully.`,
        actions: [
          {
            label: 'VIEW',
            onClick: () => history.push('/cms/ota/' + updateData.id)
          }
        ]
      });
      history.push('/cms/ota');
      setIsLoading(false);
    }
  }, [history, createData, createError, createIsLoading, updateError, updateIsLoading, updateData]);

  useEffect(() => {
    if (createError || updateError) {
      CmsSnackbarQueue.notify({
        title: `Could not ${updateError ? 'update' : 'create'} OTA. Please try again.`,
        actions: [
          {
            label: 'CLOSE'
          }
        ]
      });
      setIsLoading(false);
    }
  }, [createError, updateError]);

  useEffect(() => {
    if (otaData) {
      setRolloutPercentage(otaData.rolloutPercentage);
      if (otaData.fileMedia) {
        setFirmwareFileName(otaData.fileMedia.filename.replace('media/', ''));
      }
      if (otaData.dateRange) {
        setDateRangeFields(otaData.dateRange as DateRangeType[]);
      }
    }
  }, [otaData]);

  const { get: exportOtaPreview } = useExportOtaPreview();

  const handlePreview = () => {
    let params: {
      rolloutPercentage: number;
      id?: string;
      dateRange?: string;
      models?: string[];
    } = {
      rolloutPercentage
    };
    if (otaId !== 'new') {
      params = {
        ...params,
        id: otaId
      };
    }

    if (dateRangeFields.length > 0) {
      params = {
        ...params,
        dateRange: encodeURI(JSON.stringify(dateRangeFields))
      };
    }

    if (modelsListType && modelsListType.name === 'all') {
      const modelsList = getModelNumbers().map(model => model.name);
      params = {
        ...params,
        models: modelsList
      };
    } else if (modelsListType && modelsListType.name === 'none') {
      params = {
        ...params,
        models: []
      };
    } else {
      if (models && models.length > 0) {
        params = {
          ...params,
          models
        };
      }
    }
    exportOtaPreview(params);
  };

  const handleCancel = () => {
    history.goBack();
  };

  const handleDateRangeInputChange = (index: any, event: any) => {
    const values = [...dateRangeFields];
    const { name, value } = event.target;

    if (name === 'startYear') {
      values[index].startYear = +value;
    } else if (name === 'startWeek') {
      values[index].startWeek = +value;
    } else if (name === 'startSequence') {
      values[index].startSequence = +value;
    } else if (name === 'endYear') {
      values[index].endYear = +value;
    } else if (name === 'endWeek') {
      values[index].endWeek = +value;
    } else if (name === 'endSequence') {
      values[index].endSequence = +value;
    }

    setDateRangeFields(values);
  };

  const handleDateRangeAddFields = () => {
    const values = [...dateRangeFields];
    values.push({ startYear: 0, startWeek: 0, startSequence: 0, endYear: 0, endWeek: 0, endSequence: 0 });
    setDateRangeFields(values);
  };

  const handleDateRangeRemoveFields = (index: any) => {
    const values = [...dateRangeFields];
    values.splice(index, 1);
    setDateRangeFields(values);
  };

  const handleFocus = (event: any) => event.target.select();

  const handleFirmwareClick = (event: any) => {
    if (null !== hiddenFirmwareFileInput.current) {
      hiddenFirmwareFileInput.current.click();
    }
  };

  const handleCsvClick = (event: any) => {
    if (null !== hiddenCsvFileInput.current) {
      hiddenCsvFileInput.current.click();
    }
  };

  const handleFirmwareFileChange = async (event: any) => {
    event.preventDefault();
    const file = event.target.files;
    if (file.length < 1) {
      return;
    } else {
      const selectedFile = file[0];
      const base = await FileService.getBase64(selectedFile);
      const fileUpload: BlobWithName = FileService.dataURLToBlob(base);
      fileUpload.name = selectedFile.name.replace(/\s/g, '_');
      setFirmwareFileName(fileUpload.name as string);
      setFirmwareFileData(fileUpload);
      setMediaUploaded(true);
    }
  };

  const handleCsvFileChange = async (event: any) => {
    event.preventDefault();
    const file = event.target.files;
    if (file.length < 1) {
      return;
    } else {
      const selectedFile = file[0];
      const data = await FileService.getText(selectedFile);
      extractDateRangeData(data);
    }
  };

  const extractDateRangeData = (data: string) => {
    const rows = data.split('\n');
    const headers = rows
      .splice(0, 1)[0]
      .trim()
      .split(',');
    const newArr: any = [];

    rows.forEach(row => {
      const newRow = row.split(',');
      if (newRow[1]) {
        const obj: any = {};
        newRow.forEach((elem, i) => {
          headers[i] !== '\r' && (obj[headers[i]] = parseInt(elem));
        });
        newArr.push(obj);
      }
    });
    setDateRangeFields(newArr);
  };

  const handleCsvExport = () => {
    const csvRows: any = [['startYear', 'startWeek', 'startSequence', 'endYear', 'endWeek', 'endSequence']];
    dateRangeFields.forEach(elem => {
      csvRows.push([elem.startYear, elem.startWeek, elem.startSequence, elem.endYear, elem.endWeek, elem.endSequence]);
    });
    const csvData = csvRows.map((row: any) => row.join(',')).join('\r\n');
    const a = document.createElement('a');
    const blob = new Blob([`${csvData}`], { type: 'text/csv;charset=utf-8' });
    a.textContent = 'download';
    a.download = 'DateRangeData.csv';
    a.href = URL.createObjectURL(blob);
    a.click();
    a.href = '#';
  };

  const formatOtaRequest = (req: OtaRequestDTO, modelsListType: ModelsListType, models: string[] | null) => {
    if (modelsListType) {
      switch (modelsListType.name) {
        case 'all':
          req.models = null;
          break;
        case 'none':
          req.models = [];
          break;
        case 'custom':
          if (models && models.length > 0) {
            req.models = models;
          } else {
            req.models = [];
          }
          break;
        default:
          break;
      }
    }
    return req;
  };

  const handleSubmit = async (values: any, actions: FormikActions<any>) => {
    setVersion(values.version);
    setReleaseNotes(values.releaseNotes);
    setRolloutPercentage(values.rolloutPercentage);
    setIsLoading(true);
    const { modelOptions, modelsListType } = values;
    let modelsList = [];
    if (modelOptions) {
      modelsList = modelOptions.map((model: ModelOptions) => model.name);
      setModels(modelsList);
    }
    setModelsListType(modelsListType);
    if (mediaUploaded) {
      try {
        await uploadData();
      } catch (error) {
        setIsLoading(false);
      }
    } else {
      let updateReq: OtaRequestDTO = {
        releaseNotes: values.releaseNotes,
        version: values.version,
        rolloutPercentage: values.rolloutPercentage,
        dateRange: dateRangeFields.length > 0 ? dateRangeFields : null
      };
      updateReq = formatOtaRequest(updateReq, modelsListType, modelsList);
      update(updateReq);
    }
  };

  const onModelOptionsChange = (data: ModelOptionsInput[] | null) => {
    let formattedRes: string[] | null = null;
    if (data) formattedRes = data.map(model => model.name);
    if (formattedRes && formattedRes.length > 0) setModels(formattedRes as string[]);
  };

  const onModelListTypeChange = (data: ModelsListTypeInput) => {
    setModelsListType(data);
  };

  const uploadData = useCallback(async () => {
    if (firmwareFileData) {
      return await new Promise<void>((resolve, reject) => {
        const xhr = new XMLHttpRequest();
        const fd = new FormData();
        const bucket = `${API_URL}media/upload`;
        const key = firmwareFileData.name ? firmwareFileData.name : [Date.now().toString(), '.gbl'].join('');
        fd.append('Content-Type', 'application/octet-stream');
        fd.append('key', key);
        fd.append('filename', firmwareFileData.name || Date.now().toString());
        fd.append('file', firmwareFileData);
        xhr.open('POST', bucket, true);
        xhr.setRequestHeader('Authorization', 'Bearer ' + getAccessToken());
        xhr.onload = function() {
          if (xhr.status === 201) {
            const path = JSON.parse(xhr.response).path;
            setFileUrl(path);
            resolve();
          } else {
            CmsSnackbarQueue.notify({
              title: `${key} does not appear to be a firmware update file.`,
              actions: [
                {
                  label: 'CLOSE'
                }
              ]
            });
            setIsLoading(false);
            reject();
          }
        };

        xhr.send(fd);
      });
    }
  }, [firmwareFileData]);

  useEffect(() => {
    if (fileUrl) {
      submitOta(fileUrl);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fileUrl]);

  const submitOta = (url: string) => {
    let newOta: OtaRequestDTO = {
      releaseNotes,
      version,
      rolloutPercentage,
      dateRange: dateRangeFields.length > 0 ? dateRangeFields : null,
      filename: firmwareFileName,
      originalUrl: url,
      smallUrl: url,
      mediumUrl: url,
      largeUrl: url
    };
    newOta = formatOtaRequest(newOta, modelsListType, models);
    if (otaId === 'new') {
      create(newOta);
    } else {
      update(newOta);
    }
  };

  const getModelNumbers = () => {
    // TODO: fetch from API
    const modelNumbers = [
      { name: '0' },
      { name: '1' },
      { name: '2' },
      { name: '4' },
      { name: '12' },
      { name: '13' },
      { name: '15' },
      { name: '17' },
      { name: '19' },
      { name: '21' },
      { name: '22' },
      { name: '25' },
      { name: '26' },
      { name: '51' },
      { name: '71' },
      { name: '72' },
      { name: '74' },
      { name: '75' },
      { name: '78' },
      { name: '79' },
      { name: '80' },
      { name: '4294967295' }
    ];
    return modelNumbers;
  };

  return (
    <Container>
      <CardContainer>
        {!ready && (
          <Spinner>
            <CircularProgress size='large' />
          </Spinner>
        )}
        {ready && initialValues && (
          <>
            <FullWidthBar>
              <TableTitle>OTA Firmware</TableTitle>
            </FullWidthBar>
            <OtaInfoWrapper>
              <Formik initialValues={initialValues} onSubmit={handleSubmit} validationSchema={OtaSchema}>
                {({ values, errors, touched, handleChange, handleBlur, isSubmitting, setFieldValue }) => (
                  <Form>
                    <FormGroup>
                      <StyledLabel>Firmware File</StyledLabel>
                      <StyledInputContainer>
                        <span>{firmwareFileName}</span>
                        <div>
                          <StyledButton
                            unelevated
                            type='button'
                            label='Upload Firmware'
                            onClick={handleFirmwareClick}
                            style={{ padding: '0 35px' }}
                          />
                          <input
                            hidden
                            type='file'
                            accept='.gbl, .puff'
                            ref={hiddenFirmwareFileInput}
                            onChange={handleFirmwareFileChange}
                          />
                        </div>
                      </StyledInputContainer>
                    </FormGroup>
                    <FormGroup>
                      <StyledLabel>Version</StyledLabel>
                      <StyledTextField
                        outlined
                        type='text'
                        name='version'
                        autoComplete='off'
                        value={values.version}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        helpText={{ persistent: true, validationMsg: true, children: touched.version && errors.version }}
                      />
                    </FormGroup>
                    <FormGroup>
                      <StyledLabel>Release Notes</StyledLabel>
                      <StyledTextField
                        outlined
                        textarea
                        fullwidth
                        type='text'
                        name='releaseNotes'
                        autoComplete='off'
                        value={values.releaseNotes}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        helpText={{ persistent: true, validationMsg: true, children: touched.releaseNotes && errors.releaseNotes }}
                      />
                    </FormGroup>
                    <FormGroup>
                      <StyledMediumLabel>Target Population</StyledMediumLabel>
                      <StyledButton unelevated type='button' label='Upload CSV' onClick={handleCsvClick} style={{ padding: '0 45px' }} />
                      <input hidden type='file' accept='.csv' ref={hiddenCsvFileInput} onChange={handleCsvFileChange} />
                      <StyledButton
                        unelevated
                        type='button'
                        label='Export CSV'
                        onClick={handleCsvExport}
                        style={{ padding: '0 45px', margin: '0 30px' }}
                      />
                    </FormGroup>
                    <StyledRepeatingFormWrapper>
                      <StyledRepeatingFormHeader>
                        <StyledMediumLabel style={{ marginBottom: 0 }}>Date Range</StyledMediumLabel>
                        <StyledAddIcon icon='add_box' onClick={() => handleDateRangeAddFields()} />
                      </StyledRepeatingFormHeader>
                      <StyledRepeatingFormContent>
                        {dateRangeFields &&
                          dateRangeFields.map((field, index) => (
                            <Fragment key={`${field}~${index}`}>
                              <StyledRepeatingFormItem>
                                <FormGroup>
                                  <StyledRepeatingActionRow>
                                    <StyledMediumLabel>Start</StyledMediumLabel>
                                    <StyledCancelIcon
                                      icon={{ icon: 'cancel', size: 'small' }}
                                      onClick={() => handleDateRangeRemoveFields(index)}
                                    />
                                  </StyledRepeatingActionRow>
                                  <StyledRepeatingRow>
                                    <StyledRepeatingColumn>
                                      <StyledLabel>Year</StyledLabel>
                                      <StyledRepeatingTextField
                                        outlined
                                        type='number'
                                        name='startYear'
                                        autoComplete='off'
                                        value={field.startYear}
                                        onFocus={handleFocus}
                                        onChange={(e: any) => handleDateRangeInputChange(index, e)}
                                      />
                                    </StyledRepeatingColumn>
                                    <StyledRepeatingColumn>
                                      <StyledLabel>Week</StyledLabel>
                                      <StyledRepeatingTextField
                                        outlined
                                        type='number'
                                        name='startWeek'
                                        autoComplete='off'
                                        value={field.startWeek}
                                        onFocus={handleFocus}
                                        onChange={(e: any) => handleDateRangeInputChange(index, e)}
                                      />
                                    </StyledRepeatingColumn>
                                    <StyledRepeatingColumn>
                                      <StyledLabel>Sequence</StyledLabel>
                                      <StyledRepeatingTextField
                                        outlined
                                        type='number'
                                        name='startSequence'
                                        autoComplete='off'
                                        value={field.startSequence}
                                        onFocus={handleFocus}
                                        onChange={(e: any) => handleDateRangeInputChange(index, e)}
                                      />
                                    </StyledRepeatingColumn>
                                  </StyledRepeatingRow>
                                </FormGroup>
                                <div>
                                  <StyledMediumLabel>End</StyledMediumLabel>
                                  <StyledRepeatingRow style={{ marginBottom: 0 }}>
                                    <StyledRepeatingColumn>
                                      <StyledLabel>Year</StyledLabel>
                                      <StyledRepeatingTextField
                                        outlined
                                        type='number'
                                        name='endYear'
                                        autoComplete='off'
                                        value={field.endYear}
                                        onFocus={handleFocus}
                                        onChange={(e: any) => handleDateRangeInputChange(index, e)}
                                      />
                                    </StyledRepeatingColumn>
                                    <StyledRepeatingColumn>
                                      <StyledLabel>Week</StyledLabel>
                                      <StyledRepeatingTextField
                                        outlined
                                        type='number'
                                        name='endWeek'
                                        autoComplete='off'
                                        value={field.endWeek}
                                        onFocus={handleFocus}
                                        onChange={(e: any) => handleDateRangeInputChange(index, e)}
                                      />
                                    </StyledRepeatingColumn>
                                    <StyledRepeatingColumn>
                                      <StyledLabel>Sequence</StyledLabel>
                                      <StyledRepeatingTextField
                                        outlined
                                        type='number'
                                        name='endSequence'
                                        autoComplete='off'
                                        value={field.endSequence}
                                        onFocus={handleFocus}
                                        onChange={(e: any) => handleDateRangeInputChange(index, e)}
                                      />
                                    </StyledRepeatingColumn>
                                  </StyledRepeatingRow>
                                </div>
                              </StyledRepeatingFormItem>
                            </Fragment>
                          ))}
                      </StyledRepeatingFormContent>
                    </StyledRepeatingFormWrapper>
                    <FormGroup>
                      <StyledLabel>Rollout Percentage (%)</StyledLabel>
                      <StyledTextField
                        outlined
                        type='number'
                        min='0'
                        max='100'
                        name='rolloutPercentage'
                        autoComplete='off'
                        value={values.rolloutPercentage}
                        onFocus={handleFocus}
                        onBlur={handleBlur}
                        onChange={(e: any) => {
                          setRolloutPercentage(e.target.value);
                          handleChange(e);
                        }}
                        helpText={{
                          persistent: true,
                          validationMsg: true,
                          children: touched.rolloutPercentage && errors.rolloutPercentage
                        }}
                      />
                    </FormGroup>
                    <FormGroup>
                      <StyledLabel>Models List Type</StyledLabel>
                      <SelectField
                        isMulti={false}
                        label='Select Models List'
                        placeholder='Select Models List'
                        defaultValue={values.modelsListType}
                        onChange={(e: any) => {
                          onModelListTypeChange(e);
                          setFieldValue('modelsListType', e);
                        }}
                        name='modelsListType'
                        options={modelListTypeOptions}
                        changeOptionLabel='name'
                        changeOptionValue='id'
                      />
                    </FormGroup>
                    {values && values.modelsListType && values.modelsListType.name === 'custom' && (
                      <FormGroup>
                        <StyledLabel>Custom Models List</StyledLabel>
                        <SelectField
                          isMulti={true}
                          label='Select Models'
                          placeholder='Select Models'
                          defaultValue={values.modelOptions}
                          onChange={(e: any) => {
                            onModelOptionsChange(e);
                            setFieldValue('modelOptions', e);
                          }}
                          name='modelOptions'
                          options={getModelNumbers()}
                          changeOptionLabel='name'
                          changeOptionValue='name'
                        />
                      </FormGroup>
                    )}
                    <div style={{ display: 'flex', flexDirection: 'column' }}>
                      <ButtonContainer>
                        <StyledButton unelevated type='button' label='Preview' onClick={handlePreview} style={{ padding: '0 100px' }} />
                      </ButtonContainer>
                      <ButtonContainer style={{ marginTop: '40px' }}>
                        <StyledButton
                          unelevated
                          type='submit'
                          label='Submit'
                          style={{ padding: '0 100px' }}
                          icon={isLoading ? <CircularProgress /> : null}
                          disabled={isLoading || !firmwareFileName}
                        />
                        <StyledButton
                          unelevated
                          type='button'
                          label='cancel'
                          onClick={handleCancel}
                          style={{ padding: '0 100px', marginLeft: '20px', backgroundColor: theme.lightGrey }}
                        />
                      </ButtonContainer>
                    </div>
                  </Form>
                )}
              </Formik>
            </OtaInfoWrapper>
          </>
        )}
      </CardContainer>
      <CardContainer style={{ height: 'max-content' }}>
        {!ready && (
          <Spinner>
            <CircularProgress size='large' />
          </Spinner>
        )}
        {ready && initialValues && (
          <>
            <FullWidthBar>
              <TableTitle>Stats</TableTitle>
            </FullWidthBar>
            <OtaInfoWrapper>
              <Form>
                <FormGroup>
                  <StyledLabel>Devices Targeted</StyledLabel>
                  <StyledTextField
                    outlined
                    type='number'
                    autoComplete='off'
                    value={initialValues.otaTracking && initialValues.otaTracking.devicesTargeted}
                    readOnly
                  />
                </FormGroup>
                <FormGroup>
                  <StyledLabel>Devices Rolled Out</StyledLabel>
                  <StyledTextField
                    outlined
                    type='number'
                    autoComplete='off'
                    value={initialValues.otaTracking && initialValues.otaTracking.devicesRollout}
                    readOnly
                  />
                </FormGroup>
                <FormGroup>
                  <StyledLabel>Install Attempts</StyledLabel>
                  <StyledTextField
                    outlined
                    type='number'
                    autoComplete='off'
                    value={initialValues.otaTracking && initialValues.otaTracking.attempts}
                    readOnly
                  />
                </FormGroup>
                <FormGroup>
                  <StyledLabel>Install Successes</StyledLabel>
                  <StyledTextField
                    outlined
                    type='number'
                    autoComplete='off'
                    value={initialValues.otaTracking && initialValues.otaTracking.successes}
                    readOnly
                  />
                </FormGroup>
                <FormGroup>
                  <StyledLabel>Devices Running</StyledLabel>
                  <StyledTextField
                    outlined
                    type='number'
                    autoComplete='off'
                    value={initialValues.otaTracking && initialValues.otaTracking.devicesRunning}
                    readOnly
                  />
                </FormGroup>
                <FormGroup>
                  <StyledLabel>Install Failures</StyledLabel>
                  <StyledTextField
                    outlined
                    type='number'
                    autoComplete='off'
                    value={initialValues.otaTracking && initialValues.otaTracking.failures}
                    readOnly
                  />
                </FormGroup>
                <ButtonContainer>
                  <StyledButton
                    unelevated
                    type='button'
                    label='Download'
                    style={{ padding: '0 100px' }}
                    disabled={otaId === 'new'}
                    onClick={() => exportDownload()}
                  />
                </ButtonContainer>
              </Form>
            </OtaInfoWrapper>
          </>
        )}
      </CardContainer>
    </Container>
  );
}

export default withRouter(OtaEdit);
